import React from 'react';
import {useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const APP_URL:any = process.env.REACT_APP_HOST_URL;

export function RedirectMSFT() {

  const navigate = useNavigate();

  useEffect(() => {
    // Function to get everything after the '#'
    let flowCompleted = localStorage.getItem('flowCompleted');
  
    if(flowCompleted === 'true'){
      navigate('/auth/');
    }

    if(window.location.hash){
    const getFragment = () => {
      const hash = window.location.hash;
      return hash.substring(1); // remove the '#' at the beginning
    };
    // // Extract the fragment and set it in state
   const extractedFragment = getFragment();

    // console.log(extractedFragment);
    const params = new URLSearchParams(extractedFragment);
    
    const state = params.get('state');
    // console.log(state); // Logs the value of 'state' parameter
    
    const parts = state?.split('|');
    // replace http with https and localhost with dynamic url from react url 
    let mainUrl = APP_URL.split('www.');

    const newUrl = `https://${parts[1]}.${mainUrl[1]}/auth/redirectmsft/#${extractedFragment}`;
    
    // const newUrl = `http://${parts[1]}.localhost:3000/auth/redirectmsft/#${extractedFragment}`;

     window.location.href = newUrl;        
    
    }
  }, []);


  return (
  
        <div
        className="spinner-border text-primary"
        role="status"
      >
          <span className="visually-hidden">Loading...</span>
      </div>
 
    );
}
