
import { getPageNr, MULTI_YEAR_VIEW, PAGE_VIEW, PAGE_WIDTH, YEAR_VIEW } from '../../shared/calendar-view/calendar-view.util';
import { PickerBase } from '../../shared/picker/picker';
import { IPickerState } from '../../shared/picker/picker.types';
import { getNativeElement, initPickerElement } from '../../shared/picker/picker.util';
import { getClosestValidDate } from '../../util/date-validation';
import {
  addDays,
  addTimezone,
  constrainDate,
  createDate,
  dateTimeLocale,
  dateValueEquals,
  formatDate,
  getDateOnly,
  getDayEnd,
  getDayStart,
  getFirstDayOfWeek,
  isSameDay,
  makeDate,
  removeTimezone,
  returnDate,
} from '../../util/datetime';
import { MbscDateType } from '../../util/datetime.types.public';
import { trigger } from '../../util/dom';
import { CHANGE, INPUT } from '../../util/events';
import { constrain, find, isArray, isEmpty, isString, map, UNDEFINED } from '../../util/misc';
import { getLatestOccurrence, getNextOccurrence } from '../../util/recurrence';
import {
  MbscDatepickerActiveDateChangeEvent,
  MbscDatepickerCellClickEvent,
  MbscDatepickerControl,
  MbscDatepickerOptions,
  MbscDatepickerValue,
} from './datepicker.types.public';

// tslint:disable no-non-null-assertion
// tslint:disable directive-class-suffix
// tslint:disable directive-selector

// For backward compatibility, remove in Mobiscroll 6
export type TDatepickerControl = MbscDatepickerControl;

export const modules: { [key: string]: any } = {};

export const RANGE_SEPARATOR = ' - ';

const CALENDAR_CTRL: MbscDatepickerControl[] = ['calendar'];
const INVALID_ALL = [{ recurring: { repeat: 'daily' } }];

interface IValueRepresentation {
  [key: string]: Date;
}

interface IDValueRepresentation {
  date?: IValueRepresentation;
  start?: number;
  end?: number;
}

/** @hidden */
// tslint:disable-next-line interface-name
export interface MbscDatepickerState extends IPickerState {
  activeTab?: string;
  hoverDate?: number;
  isLarge?: boolean;
  maxPopupWidth?: number;
  widthType?: 'sm' | 'md';
}

function notActive(active: 'start' | 'end'): 'start' | 'end' {
  return active === 'start' ? 'end' : 'start';
}

/**
 * Returns the range selection start/end dates calculated from a date.
 * Takes into account the selectSize and firstSelectDay options and calculates the selection start/end dates
 * from the passed date.
 */
function getPresetRange(timestamp: number, s: MbscDatepickerOptions) {
  const date = new Date(timestamp);
  const firstSelectDay = s.firstSelectDay !== UNDEFINED ? s.firstSelectDay : s.firstDay!;
  const start = getFirstDayOfWeek(date, s, firstSelectDay);
  const end = new Date(start.getFullYear(), start.getMonth(), start.getDate() + s.selectSize! - 1);
  return { start, end };
}

/** @hidden */

export class DatepickerBase extends PickerBase<MbscDatepickerOptions, MbscDatepickerState, MbscDatepickerValue, IDValueRepresentation> {
  /** @hidden */
  public static defaults: MbscDatepickerOptions = {
    ...dateTimeLocale,
    ...PickerBase.defaults,
    activeElm: '.mbsc-calendar-cell[tabindex="0"]',
    controls: CALENDAR_CTRL,
    dateText: 'Date',
    inRangeInvalid: false,
    inputTyping: true,
    rangeEndHelp: 'Please select',
    rangeEndLabel: 'End',
    rangeHighlight: true,
    rangeStartHelp: 'Please select',
    rangeStartLabel: 'Start',
    select: 'date',
    selectSize: 7,
    selectedText: '{count} selected',
    showOnClick: true,
    // showOnFocus: true,
    timeText: 'Time',
  };

  // tslint:disable variable-name

  protected static _name = 'Datepicker';

  public _activeTab?: string;
  public _activeSelect?: 'start' | 'end';
  public _controls!: any[];
  public _controlsClass?: string;
  /** Whether of not to show the range picker start and end selection controls */
  public _renderControls?: boolean;
  public _renderTabs?: boolean;
  public _selectedTime?: Date;

  /**
   * @hidden
   * The formatted start value that appears on the start/end selection control
   */
  public _tempStartText?: string;
  /**
   * @hidden
   * The formatted end value that appears on the start/end selection control
   */
  public _tempEndText?: string;

  protected _needsWidth?: boolean;

  /** Reference to the start input element in case of the range picker */
  private _startInput?: HTMLInputElement;
  /** Reference to the end input element in case of the range picker */
  private _endInput?: HTMLInputElement;

  private _iso: any = {};
  private _hasCalendar!: boolean;
  private _hasDate!: boolean;
  private _hasTime!: boolean;
  private _hasTimegrid!: boolean;
  private _max?: Date | null;
  private _min?: Date | null;
  /**
   * Holds the minimum time that can be selected for each day.
   * It's a Date object that we use only the time part of.
   */
  private _minTime?: Date;
  /**
   * Holds the maximum time that can be selected for each day.
   * It's a Date object that we use only the time part of.
   */
  private _maxTime?: Date | null;
  /**
   * We pass this date to the calendar, when we want to navigate it to a certain date.
   * The calendar takes this into account only once every time the _active value changes.
   * Otherwise when navigated from UI, this value is disregarded.
   */
  private _active?: number;
  private _valueFormat!: string;
  /** Holds the limited max option which is manipulated in the case of range selection based on invalids */
  private _maxLimited?: Date;
  /** Holds the limited min option which is manipulated in the case of range selection on the datetime control based on the start date */
  private _minLimited?: Date;
  /**
   * Holds the limited min option which is passed to the time control
   * It's required because in the case of the calendar the min setting should not be limited, but
   * for the time control it does. So in case when there is a time and a calendar, the two passed options differ.
   */
  private _minTimeLimited?: Date;
  private _nextInvalid?: Date | null;
  /** Holds the previously selected start date as a timestamp in case of the range picker */
  private _prevStart?: number;
  /**
   * Holds the last selected date in the case of the calendar
   * NOTE: It's needed in the case of the range picker, when both of the start and end dates are selected, and we change the end selection
   * we can't decide which date was clicked otherwise, because the calendar change only knows the selected dates. So if we click the same
   * day (start or end), we can't tell if the start or the end date was clicked.
   * TODO: simplify the onCalendarChange function based on this value - currently it calculates this value from the current selection if
   * it can, otherwise uses this value
   */
  private _lastSelected?: Date;
  private _resetStartInput?: () => void;
  private _resetEndInput?: () => void;
  private _shouldInitInputs?: boolean;
  /**
   * In the case of the range picker it specifies if we are making a new selection.
   * When true, it means we need to cycle the active selection. Otherwise we refine the
   * selection and we don't have to cycle it.
   * Generally, we make a new selection when we open the picker (doesn't matter if there was
   * already a selected date or not).
   */
  private _newSelection?: boolean;
  private _selectedDate?: number;

  /** When switching between date and datetime we have to save the start and end dates not to lose data */
  private _savedEndValue?: number;
  /** When switching between date and datetime we have to save the start and end dates not to lose data */
  private _savedStartValue?: number;
  private _clearSaved?: boolean;
  private _prevStateValue?: any;

  /** @hidden */
  public _onActiveChange = (ev: any) => {
    this._active = ev.date;
    this.forceUpdate();
  };

  /** @hidden */
  public _onResize = (ev: any) => {
    const viewportWidth = ev.windowWidth;

    // Will prevent the immediate positioning of the popup,
    // postponing it to the next cycle, with the potential new options (if responsive)
    ev.cancel = this.state.width !== viewportWidth;

    this.setState({
      isLarge: ev.isLarge,
      maxPopupWidth: ev.maxPopupWidth,
      width: viewportWidth,
      widthType: viewportWidth > 600 ? 'md' : 'sm',
    });
    // return this._hook('onPosition', ev);
  };

  /** @hidden */
  public _onDayHoverIn = ({ date, hidden }: { date: Date; hidden: boolean }) => {
    this.setState({ hoverDate: hidden ? UNDEFINED : +date });
  };

  /** @hidden */
  public _onDayHoverOut = ({ date }: { date: Date }) => {
    if (this.state.hoverDate === +date) {
      this.setState({ hoverDate: UNDEFINED });
    }
  };

  /**
   * @hidden
   * Saves the last clicked date on the calendar
   */
  public _onCellClick = (args: any) => {
    this._lastSelected = addTimezone(this.s, args.date as Date);
    args.active = this._activeSelect;
    this._hook<MbscDatepickerCellClickEvent>('onCellClick', args);
  };

  /** @hidden */
  public _onCalendarChange = (ev: any) => {
    this._tempValueSet = false;
    const s = this.s;
    let tempValueRep: IDValueRepresentation = this._copy(this._tempValueRep);
    const date: any = map(ev.value, (item) => addTimezone(s, item));
    const isPreset = s.select === 'preset-range';
    const isRange = s.select === 'range';
    const newSelection = isRange && this._newSelection;
    const slide = (isRange || isPreset) && s.exclusiveEndDates && !this._hasTime;
    if (slide && tempValueRep.end) {
      tempValueRep.end = +getDayStart(s, createDate(s, tempValueRep.end - 1));
    }

    // if time was set previously set it to the new selected date as well
    if (this._hasTime && this._selectedTime && !isRange) {
      if (this.s.selectMultiple) {
        const lastSelection: Date = date[date.length - 1];
        if (lastSelection) {
          lastSelection.setHours(this._selectedTime.getHours(), this._selectedTime.getMinutes());
        }
      } else {
        date.setHours(this._selectedTime.getHours(), this._selectedTime.getMinutes());
      }
    }

    if (isRange || isPreset) {
      // get the newly selected value
      const oldValue = this._getDate(tempValueRep) as Array<Date | null>;
      const oldRangeDate: Date[] = oldValue.filter((v) => v !== null) as Date[];
      const oldRange: number[] = oldRangeDate.map((dateValue) => +dateValue);
      const oldRangeCut: number[] = oldRangeDate.map((v) => +getDateOnly(v)) as number[];
      const [newValue] = (date as Date[]).filter((v) => oldRangeCut.indexOf(+v) < 0);
      if (isPreset) {
        // preset-range
        if (newValue) {
          // when no new value is selected, we shouldn't do anything
          const { start, end } = getPresetRange(+newValue, s);
          tempValueRep.start = +start;
          tempValueRep.end = +end;
        }
      } else {
        // range
        let cycle = !this._hasTime;
        const cycleAndLabels = this._renderControls;
        const activeSelect = this._activeSelect!;
        const notActiveSelect = notActive(activeSelect);
        if (newValue) {
          if (this._hasTime && this._selectedTime) {
            newValue.setHours(
              this._selectedTime.getHours(),
              this._selectedTime.getMinutes(),
              this._selectedTime.getSeconds(),
              this._selectedTime.getMilliseconds(),
            );
          }

          switch (oldRange.length) {
            case 0: {
              // simple start/end date selection
              tempValueRep = {};
              tempValueRep[activeSelect] = +newValue;
              break;
            }
            case 1: {
              if (cycleAndLabels) {
                // oneCycle selection
                tempValueRep[activeSelect] = +newValue;
                break;
              }
              if (oldRange[0] > +newValue || this._activeSelect === 'start') {
                // new start date selection
                if (this._hasTime) {
                  tempValueRep[activeSelect] = +newValue;
                } else {
                  tempValueRep = { start: +newValue };
                  cycle = false;
                }
              } else {
                // simple end date selection
                tempValueRep.end = +newValue;
              }
              break;
            }
            case 2: {
              if (cycleAndLabels) {
                // oneCycle selection
                tempValueRep[activeSelect] = +newValue;
                break;
              }
              if (oldRange[0] > +newValue || this._activeSelect === 'start') {
                if (this._hasTime) {
                  tempValueRep[activeSelect] = +newValue;
                } else {
                  tempValueRep = { start: +newValue };
                  if (this._activeSelect === 'end') {
                    cycle = false;
                  }
                }
              } else if (this._activeSelect === 'end') {
                // new end date selection
                tempValueRep.end = +newValue;
              }
              break;
            }
          }
          // validate cross start/end (when the start is bigger than end)
          if (cycleAndLabels && tempValueRep.start && tempValueRep.end && tempValueRep.start > tempValueRep.end) {
            tempValueRep = {
              start: +newValue,
            };
            this._setActiveSelect('end');
          }
        } else {
          // either the already selected start or end date were selected
          let newDate;
          if (oldRange.length === 1) {
            // only the start date was selected
            newDate = createDate(s, oldRange[0]);
          } else {
            newDate = this._lastSelected!;
          }

          if (this._hasTime && this._selectedTime) {
            newDate.setHours(
              this._selectedTime.getHours(),
              this._selectedTime.getMinutes(),
              this._selectedTime.getSeconds(),
              this._selectedTime.getMilliseconds(),
            );
          } else if (
            !s.exclusiveEndDates &&
            !this._hasTime &&
            this._activeSelect === 'end' &&
            oldValue[0] &&
            isSameDay(newDate, oldValue[0]!)
          ) {
            newDate.setHours(23, 59, 59, 999);
          }

          if (cycleAndLabels || this._hasTime) {
            // oneCycle selection
            tempValueRep[activeSelect] = +newDate;
          } else if (this._activeSelect === 'start') {
            tempValueRep = { start: +newDate };
          } else {
            // _activeSelect === 'end'
            tempValueRep.end = +newDate;
          }
        }

        // validate the new range
        if (tempValueRep.start && tempValueRep.end) {
          // this can occur if a time control is present and we change the start date to the same day the end is on,
          // but the end date's time is am and the start date was pm
          if (tempValueRep.start > tempValueRep.end) {
            const st = createDate(s, tempValueRep.start);
            const ed = createDate(s, tempValueRep.end);
            if (isSameDay(st, ed)) {
              ed.setHours(st.getHours(), st.getMinutes(), st.getSeconds(), st.getMilliseconds());
              tempValueRep.end = +ed;
            } else {
              tempValueRep.end = UNDEFINED;
            }
          }
          // validate the range for minRange
          if (s.minRange && tempValueRep.end) {
            const newEnd = this._hasTime ? tempValueRep.start + s.minRange : +addDays(createDate(s, tempValueRep.start), s.minRange - 1);
            // if we slide the selection to a lesser range than the minimum,
            // (this can be done only when there's a time control and we change the date, not the time)
            // we let the time control validate the time part = we don't clear the end
            if (tempValueRep.end < newEnd && (!this._hasTime || activeSelect === 'start')) {
              tempValueRep.end = UNDEFINED;
            }
          }
          // validate the range for maxRange
          if (s.maxRange && tempValueRep.end) {
            // the end check is needed because the minRange could have cleared the end above
            // if we slide the selection to a greater range than the maximum,
            // (this can be done only when there's a time control and we change the date, not the time)
            // we let the time control validate the time part = we don't clear the end
            const newEnd = this._hasTime ? tempValueRep.start + s.maxRange : +addDays(createDate(s, tempValueRep.start), s.maxRange) - 1;
            if (tempValueRep.end > newEnd && (!this._hasTime || activeSelect === 'start')) {
              tempValueRep.end = UNDEFINED;
            }
          }
          // validate the range for inRangeInvalids
          // we refine the selection and invalids are not allowed in the range
          // then need to refine/clear the end date
          if (tempValueRep.end && activeSelect === 'start' && !s.inRangeInvalid) {
            const nextInvalid = s.valid
              ? addDays(getLatestOccurrence(s.valid, createDate(s, tempValueRep.start), s), 1)
              : getNextOccurrence(s.invalid || [], createDate(s, tempValueRep.start), s);
            if (nextInvalid !== null && +nextInvalid < tempValueRep.end!) {
              // there is an invalid date in the range
              tempValueRep.end = UNDEFINED;
            }
          }
        }

        // Cycling is based on whether we are refining the selection or creating a new selection.
        // When we open the picker, we always start a new selection (no matter if there was already a selected date)
        // Also sometimes the cycling is prevented, for example when the selection is forced to be the not active date
        // (when selecting an end that is less than the start)
        // Cycling is also prevented when the time control is shown. Then we need to manually switch the active date.
        if (cycle && (this._newSelection || !this._renderControls || (this._newSelection === UNDEFINED && this.s.display === 'inline'))) {
          this._setActiveSelect(notActiveSelect);
          this._newSelection = false;
        }
      }
    } else {
      // single or multiple date selection
      tempValueRep = { date: {} };
      if (this.s.selectMultiple) {
        for (const dateVal of date) {
          tempValueRep.date![+dateVal] = dateVal;
        }
      } else {
        if (this._hasTime) {
          const time = this._selectedTime || new Date();
          date.setHours(time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds());
        }
        tempValueRep.date![+date] = date;
      }
    }

    this._tempValueRep = tempValueRep;
    if (slide && tempValueRep.end) {
      tempValueRep.end = +getDayStart(s, addDays(createDate(s, tempValueRep.end), 1));
    }
    this._setOrUpdate();

    // In case of single live selection close the picker when a date is clicked
    if (
      this._live &&
      (!this.s.selectMultiple || isRange) &&
      !this._hasTime &&
      (!isRange || (tempValueRep.start && tempValueRep.end && !newSelection))
    ) {
      this.close();
    }
  };

  /** @hidden */
  public _onDatetimeChange = (ev: any) => {
    const s = this.s;
    const isRange = s.select === 'range';
    const value = addTimezone(s, ev.value);
    const date: Date = this._hasTime ? value : getDateOnly(value);
    const d = +date;

    this._tempValueSet = false;

    let tempValueRep = this._copy(this._tempValueRep);
    const slide = isRange && s.exclusiveEndDates && !this._hasTime;
    if (slide && tempValueRep.end) {
      tempValueRep.end = +getDayStart(s, createDate(s, tempValueRep.end - 1));
    }

    if (isRange) {
      if (this._activeSelect === 'start') {
        if (this._hasTime && this._selectedTime) {
          date.setHours(
            this._selectedTime!.getHours(),
            this._selectedTime.getMinutes(),
            this._selectedTime.getSeconds(),
            this._selectedTime.getMilliseconds(),
          );
        }
        tempValueRep.start = d;
        if (tempValueRep.end) {
          // validate the range for minRange
          const minRange = s.minRange && !this._hasTime ? (s.minRange - 1) * 24 * 60 * 60 * 1000 - 1 : s.minRange || 0;
          const range = tempValueRep.end - tempValueRep.start;
          if (range < minRange) {
            tempValueRep.end = UNDEFINED;
          }
        }
      } else {
        // end selection
        if (this._hasTime) {
          if (this._selectedTime) {
            date.setHours(
              this._selectedTime!.getHours(),
              this._selectedTime.getMinutes(),
              this._selectedTime.getSeconds(),
              this._selectedTime.getMilliseconds(),
            );
          }
        } else if (tempValueRep.start === +getDateOnly(date) && !s.exclusiveEndDates) {
          date.setHours(23, 59, 59, 999);
        }
        tempValueRep.end = +date;
      }
    } else {
      // single date selection (there's no multiselect in the case of the datetime scroller)
      if (this._hasTime && this._hasDate && s.controls!.indexOf('datetime') < 0) {
        const time = this._selectedTime || new Date();
        date.setHours(time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds());
      } else {
        this._selectedTime = createDate(s, date);
      }
      tempValueRep = { date: {} };
      tempValueRep.date![+date] = date;
    }

    this._tempValueRep = tempValueRep;
    if (slide && tempValueRep.end) {
      tempValueRep.end = +getDayStart(s, addDays(createDate(s, tempValueRep.end), 1));
    }

    this._setOrUpdate();
  };

  /** @hidden */
  public _onTimePartChange = (ev: any) => {
    this._tempValueSet = false;
    const s = this.s;
    const isRange = s.select === 'range';
    const date: Date = addTimezone(s, ev.value);
    this._selectedTime = date; // save the time part selection - this is needed when there's no date part selection yet,
    // it will be added when the date is selected

    if (isRange) {
      // range selection
      const values = this._getDate(this._tempValueRep) as Date[];
      const valueIndex = this._activeSelect === 'start' ? 0 : 1;
      if (values[valueIndex]) {
        const value: Date = createDate(s, values[valueIndex]);
        // update the time part in the active selection
        value.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
        values[valueIndex] = value;
        if (this._activeSelect === 'start' && +value > +values[1]) {
          values.length = 1;
        }
        this._tempValueRep = this._parse(values);
      } else {
        this._selectedTime.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
      }
    } else if (!s.selectMultiple) {
      // single select either with calendar or date
      const value: Date = this._getDate(this._tempValueRep) as Date;
      if (value) {
        value.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
        this._tempValueRep = { date: {} };
        this._tempValueRep.date![+value] = value;
      } else {
        this._selectedTime.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
        // In live mode, a set will be triggered later instead of the forced update, but no value change is happening until
        // a date is also selected, so no update will happen to re-render the Datepicker and pass the newly selected time
        // to the time scroller. So we need to trigger an update here.
        if (this._live) {
          this.forceUpdate();
        }
      }
    }
    this._setOrUpdate();
  };

  /** @hidden */
  public _changeActiveTab = (ev: any) => {
    this.setState({ activeTab: ev.target.value });
  };

  /** @hidden */
  public _changeActiveSelect = (ev: any) => {
    const active = ev.target.value;
    this._setActiveSelect(active);
    this.setActiveDate(active);
  };

  /**
   * Sets which date or time is currently selected (start or end).
   * @param active Specifies which one should be active start or end selection.
   */
  public setActiveDate(active: 'start' | 'end') {
    const notActiveSelect = notActive(active);
    this._activeSelect = active;
    const activeValue = this._tempValueRep[active];
    const notActiveValue = this._tempValueRep[notActiveSelect];
    if ((this._tempValueRep.start && this._tempValueRep.end) || (!activeValue && notActiveValue)) {
      this._newSelection = false;
    } else if (activeValue && !notActiveValue) {
      this._newSelection = true;
    }
    if (activeValue) {
      this._active = activeValue;
    }

    // when switching the active selection in the case of the timegrid, we should clear the selected time
    if (!activeValue && this._hasTimegrid) {
      this._selectedTime = UNDEFINED;
    }

    this.forceUpdate();
  }

  /** Returns the temporary value selected on the datepicker. */
  public getTempVal(): MbscDatepickerValue {
    return super.getTempVal();
  }

  /**
   * Sets the Datepicker temporary value. This temp value is shown on the picker until the selection.
   * In the case of inline mode or when the touchUi setting is false the value will be set to the Model as well,
   * since in these cases there's no temporary value.
   * @param value The value to set to the Datepicker as temporary value
   */
  public setTempVal(value: MbscDatepickerValue) {
    super.setTempVal(value);
  }

  /**
   * Display a specific month on the calendar without setting the date.
   * @param date - Date to navigate to. Can be a Date object, ISO8601 date string, or moment object.
   */
  public navigate(date: MbscDateType) {
    this._active = +makeDate(date);
    this.forceUpdate();
  }

  /** @hidden */
  public _clearEnd = () => {
    this._tempValueRep.end = UNDEFINED;
    // if there's a timegrid, we should also clear the time part of the selection
    if (this._hasTimegrid) {
      this._selectedTime = UNDEFINED;
    }
    this._setOrUpdate();
  };

  /** @hidden */
  public _clearStart = () => {
    this._tempValueRep = {};
    this._newSelection = true;
    // if there's a timegrid, we should also clear the time part of the selection
    if (this._hasTimegrid) {
      this._selectedTime = UNDEFINED;
    }
    this._setOrUpdate();
  };

  /** @hidden */
  public _shouldValidate(s: MbscDatepickerOptions, prevS: MbscDatepickerOptions) {
    return (
      s.controls !== prevS.controls ||
      s.dataTimezone !== prevS.dataTimezone ||
      s.displayTimezone !== prevS.displayTimezone ||
      s.dateFormat !== prevS.dateFormat ||
      s.timeFormat !== prevS.timeFormat ||
      s.locale !== prevS.locale ||
      s.min !== prevS.min ||
      s.max !== prevS.max
    );
  }

  /** @hidden */
  public _valueEquals(v1: any, v2: any) {
    const side1 = (isArray(v1) && v1.length === 0) || v1 === UNDEFINED || v1 === null;
    const side2 = (isArray(v2) && v2.length === 0) || v2 === UNDEFINED || v2 === null;
    return (side1 && side1 === side2) || dateValueEquals(v1, v2, this.s);
  }

  /** @hidden */
  public setVal(value: any): void {
    if (this.s.select === 'range' && value) {
      const [start, end] = value;
      this._savedStartValue = +makeDate(start, this.s, this._valueFormat);
      this._savedEndValue = +makeDate(end, this.s, this._valueFormat);
    }
    super.setVal(value);
  }

  // tslint:enable variable-name

  // protected _init() {
  //   // Register the injected modules
  //   if (this.props.modules) {
  //     this.props.modules.forEach((module: any) => {
  //       modules[module._name] = module;
  //     });
  //   }
  // }

  protected _render(s: MbscDatepickerOptions, state: MbscDatepickerState) {
    // when invalids can be part of the range, we disregard the rangeEndInvalid option
    if (s.inRangeInvalid) {
      s.rangeEndInvalid = false;
    }

    // when using the 'preset-range' selection mode, we only support the calendar control, and we disregard the controls option
    // we need to overwrite the controls option because in angular the prevS is compared to it, and the controls are destroyed
    // and recreated base on the s.controls, and otherwise it results in an error.
    if (s.select === 'preset-range') {
      s.controls = CALENDAR_CTRL;
    }

    // If we have display timezone set, default to exclusive end dates
    if (s.exclusiveEndDates === UNDEFINED) {
      s.exclusiveEndDates = !!s.displayTimezone;
    }

    const hadTime = this._hasTime;
    const hasDate = (this._hasDate = !!find(s.controls!, (item) => /date|calendar/.test(item)));
    const hasTime = (this._hasTime = !!find(s.controls!, (item) => /time/.test(item)));

    // if there's no time control on the picker, we disregard the timezone options
    if (!hasTime) {
      s.timezonePlugin = s.dataTimezone = s.displayTimezone = UNDEFINED;
    }

    // invalidate all, when valid option is defined
    if (s.valid && (!s.invalid || hasTime)) {
      s.invalid = INVALID_ALL;
    }

    const prevS = this._prevS;
    const {
      buttons, // needed for decomposition - we should not pass this to the subcomponents in the `other` props
      calendarSize,
      children,
      className,
      controls,
      cssClass,
      element,
      modelValue,
      onDestroy,
      onInit,
      // onMarkupReady,
      onTempChange, // needed for decomposition
      responsive, // needed for decomposition
      select,
      selectMultiple, // needed for decomposition - only passed to the calendar when no range selection
      tabs,
      ...other
    } = s;

    const widthType: 'sm' | 'md' = state.widthType || 'sm';
    const isRange = select !== 'date';
    this._renderTabs = controls!.length > 1 && (tabs === 'auto' ? widthType === 'sm' : tabs);

    // Switching between range and date selection
    // When there are already selected values, then we try to move them between selections
    if (select !== prevS.select && this._tempValueRep) {
      if (isRange && this._tempValueRep.date) {
        const [start, end] = Object.keys(this._tempValueRep.date)
          .map((v) => +v)
          .sort();
        this._tempValueRep.start = start;
        this._tempValueRep.end = end;
        this._tempValueRep.date = UNDEFINED;
        this._tempValueText = this._format(this._tempValueRep);
        setTimeout(() => {
          // timeout needed because we can't call set in render directly
          this.set();
        });
      } else if (!isRange && (this._tempValueRep.start || this._tempValueRep.end)) {
        if (!this._tempValueRep.date) {
          this._tempValueRep.date = {};
        }
        const first = (this._tempValueRep.start || this._tempValueRep.end) as number;
        this._tempValueRep.date[first] = new Date(first);
        const second = (this._tempValueRep.end || this._tempValueRep.start) as number;
        if (second !== first && s.selectMultiple) {
          this._tempValueRep.date[second] = new Date(second);
        }
        this._tempValueRep.start = UNDEFINED;
        this._tempValueRep.end = UNDEFINED;
        this._tempValueText = this._format(this._tempValueRep);
        setTimeout(() => {
          // timeout needed because we can't call set in render directly
          this.set();
        });
      }
    }

    if (s.min !== prevS.min) {
      this._min = isEmpty(s.min) ? UNDEFINED : makeDate(s.min, s, s.dateFormat!);
    }

    if (s.max !== prevS.max) {
      this._max = isEmpty(s.max) ? UNDEFINED : makeDate(s.max, s, s.dateFormat!);
    }

    if (s.minTime !== prevS.minTime) {
      this._minTime = isEmpty(s.minTime) ? UNDEFINED : makeDate(s.minTime, s, s.timeFormat);
    }

    if (s.maxTime !== prevS.maxTime) {
      this._maxTime = isEmpty(s.maxTime) ? UNDEFINED : makeDate(s.maxTime, s, s.timeFormat);
    }

    const tempValueRepEnd = this._tempValueRep && this._tempValueRep.end;
    const tempValueRepStart = this._tempValueRep && this._tempValueRep.start;
    const format = (hasDate ? s.dateFormat! : '') + (hasTime ? (hasDate ? s.separator! : '') + s.timeFormat! : '');
    const controlsChanged = controls !== prevS.controls;

    // Process the controls
    if (controlsChanged) {
      this._controls = [];
      this._controlsClass = '';
      this._hasCalendar = false;
      this._hasTimegrid = false;
      for (const control of controls!) {
        if (control === 'timegrid') {
          this._hasTimegrid = true;
        }
        if (control === 'calendar') {
          this._hasCalendar = true;
        }
        this._controls.push({
          Component: modules[control === 'calendar' ? 'Calendar' : control === 'timegrid' ? 'Timegrid' : 'Datetime'],
          name: control,
          title: control === 'time' || control === 'timegrid' ? s.timeText : s.dateText,
        });
        this._controlsClass += ' mbsc-datepicker-control-' + control;
      }
      // when changing from a distinct time picker to a datetime the selected time will be tracked
      // by the datetime control, so the _selectedTime should be undefined
      if (!hasTime) {
        this._selectedTime = UNDEFINED;
      }
    }

    this._renderControls = isRange && select !== 'preset-range' && (s.showRangeLabels !== UNDEFINED ? s.showRangeLabels : true);
    this._nullSupport = s.display !== 'inline' || select !== 'date' || s.selectMultiple === true;
    this._valueFormat = format;
    this._activeTab = state.activeTab || controls![0];

    super._render(s, state); // TODO _valueFormat is undefined at initial load if this is on the top check if there's any other way

    // When controls change, it triggers a readValue() in the base render.
    // It will also trigger a change with a setTimeout if the value changed during this readValue.
    // The following setTimeout should come after the setTimeout of the picker base, so the picker base won't override
    // the value. (The parsed one - from the picker base - will not have time info)
    if (controlsChanged && isRange && s.exclusiveEndDates && hasTime !== hadTime && (tempValueRepEnd || tempValueRepStart)) {
      // needed for the setTimeout clojure to save these values,
      // because sometimes a (strict) render cycle again before the setTimeout
      // runs and the _savedStartValue's are overwritten with undefined before it gets restored
      const savedStart = this._savedStartValue;
      const savedEnd = this._savedEndValue;
      setTimeout(() => {
        if (hasTime) {
          // from date to datetime
          this._tempValueRep.start = savedStart || tempValueRepStart;
          this._tempValueRep.end = savedEnd || tempValueRepEnd;
        } else {
          // from datetime to date
          this._savedStartValue = tempValueRepStart;
          this._savedEndValue = tempValueRepEnd;
          this._clearSaved = false;
          const ss = {
            ...s,
            dataTimezone: this.props.dataTimezone,
            displayTimezone: this.props.displayTimezone,
            timezonePlugin: this.props.timezonePlugin,
          };
          if (tempValueRepStart) {
            // might be empty
            this._tempValueRep.start = +removeTimezone(getDayStart(ss, createDate(ss, tempValueRepStart)));
          }
          if (tempValueRepEnd) {
            const tzonedEnd: any = createDate(ss, tempValueRepEnd - 1);
            this._tempValueRep.end = +removeTimezone(createDate(ss, +getDayEnd(ss, tzonedEnd) + 1));
          }
        }

        this._valueText = this._tempValueText = this._format(this._tempValueRep);
        this._valueTextChange = true;
        this.set();
      });
      // prevent the output of the text in the input to prevent flickering, because
      // we rewrite the value in the next cycle anyway
      this._valueTextChange = false;
    }

    // if the value changes between date/datetime switches, we should reset the saved times
    const controlled = s.value !== UNDEFINED;
    const valueChanged = controlled ? s.value !== prevS.value : state.value !== this._prevStateValue;
    if (isRange && this._clearSaved && valueChanged) {
      this._savedEndValue = this._savedStartValue = UNDEFINED;
    }
    this._clearSaved = true;

    if (s.headerText !== prevS.headerText || s.selectCounter !== prevS.selectCounter || s.selectMultiple !== prevS.selectMultiple) {
      this._setHeader();
    }

    // In the case of the timegrid control, we need to turn off the scrollLock, otherwise the control is not scrollable
    this._scrollLock = s.scrollLock !== UNDEFINED ? s.scrollLock : !this._hasTimegrid;

    // overwrite show input based on start/end input in range
    // we only show the input when not in inline mode and if there are no custom inputs passed in range mode
    this._showInput = s.showInput !== UNDEFINED ? s.showInput : this._showInput && (!isRange || (!s.startInput && !s.endInput));

    // overwrite the initialization of inputs - take into account the start and end inputs
    this._shouldInitInputs =
      this._shouldInitInputs || select !== prevS.select || s.startInput !== prevS.startInput || s.endInput !== prevS.endInput;
    this._shouldInitInput = this._shouldInitInput || this._shouldInitInputs;

    // Determine the ISO parts from format
    if (
      controlsChanged ||
      s.dateWheels !== prevS.dateWheels ||
      s.timeWheels !== prevS.timeWheels ||
      s.dateFormat !== prevS.dateFormat ||
      s.timeFormat !== prevS.timeFormat
    ) {
      const dateParts = s.dateWheels || s.dateFormat!;
      const timeParts = s.timeWheels || s.timeFormat!;
      const isoParts = (this._iso = {} as any);

      if (hasDate) {
        if (/y/i.test(dateParts)) {
          isoParts.y = 1;
        }

        if (/M/.test(dateParts)) {
          isoParts.y = 1;
          isoParts.m = 1;
        }

        if (/d/i.test(dateParts)) {
          isoParts.y = 1;
          isoParts.m = 1;
          isoParts.d = 1;
        }
      }

      if (hasTime) {
        if (/h/i.test(timeParts)) {
          isoParts.h = 1;
        }

        if (/m/.test(timeParts)) {
          isoParts.i = 1;
        }

        if (/s/i.test(timeParts)) {
          isoParts.s = 1;
        }
      }
    }

    let setButtonDisabled: boolean;

    if (isRange) {
      // Set active selection
      if (this._activeSelect === UNDEFINED) {
        this._setActiveSelect('start', true);
      }

      // Disable the set button if the selection is not ready
      setButtonDisabled = this._selectionNotReady();
    } else {
      this._activeSelect = UNDEFINED;
      // enable the set button - when switching between range and date select, the set button can be stuck otherwise
      setButtonDisabled = false;
    }

    if (this._buttons) {
      // find the set button
      const setBtn = find(this._buttons, (btn) => btn.name === 'set');
      if (setBtn && setBtn.disabled !== setButtonDisabled) {
        setBtn.disabled = setButtonDisabled;
        // Forces re-render
        this._buttons = [...this._buttons];
      }
    }

    const activeSelect = this._activeSelect; // saved for optimization

    this._needsWidth =
      (s.display === 'anchored' ||
        s.display === 'center' ||
        (s.display !== 'inline' && state.isLarge) ||
        (controls!.length > 1 && !tabs)) &&
      s.width === UNDEFINED;

    // limit selection range based on invalids and min/max range options

    const maxDate: Date | undefined = s.max !== UNDEFINED ? makeDate(s.max, s, format) : UNDEFINED;
    const minDate: Date | undefined = s.min !== UNDEFINED ? makeDate(s.min, s, format) : UNDEFINED;
    this._maxLimited = maxDate;
    this._minLimited = minDate;

    // get the next invalid date from the selected start date and cache it
    // only calculate if the start date or the invalids changed
    const selectedStart = this._tempValueRep.start;
    if (selectedStart && (this._prevStart !== selectedStart || prevS.valid !== s.valid || prevS.invalid !== s.invalid)) {
      const startDate = createDate(s, selectedStart);
      this._nextInvalid = s.valid
        ? addDays(getLatestOccurrence(s.valid, startDate, s), 1)
        : getNextOccurrence(s.invalid || [], startDate, s);
    }

    const endSelection = activeSelect === 'end' && selectedStart;
    if (endSelection) {
      if (!s.inRangeInvalid) {
        const nextInvalid = this._nextInvalid;
        if (nextInvalid) {
          // in case the range end can occur on an invalid date, we need to allow that date to be selected
          // so we need to extend the _maxLimited with an addition day
          // we also need to add it as a valid date late on when we pass the options to the controls
          if (s.rangeEndInvalid) {
            this._maxLimited = createDate(s, +addDays(nextInvalid, 1) - 1);
          } else {
            this._maxLimited = createDate(s, +nextInvalid - 1);
          }
        }
      }
      if (!this._hasCalendar || hasTime) {
        if (!this._minLimited || makeDate(this._minLimited, s, format) < createDate(s, selectedStart!)) {
          this._minLimited = createDate(s, this._tempValueRep.start!);
        }
      }
    }

    this._minTimeLimited = this._minLimited;

    if (endSelection) {
      if (s.minRange) {
        // we take out the 0 range as well by not comparing to UNDEFINED
        const minLimited = hasTime
          ? this._tempValueRep.start! + s.minRange
          : +addDays(createDate(s, this._tempValueRep.start!), s.minRange) - 1;
        if (!this._minLimited || +(makeDate(this._minLimited, s, format) as Date) < minLimited) {
          this._minLimited = createDate(s, minLimited);
          this._minTimeLimited = this._minLimited;
        }
      }
      if (this._minTimeLimited === UNDEFINED && this._tempValueRep.start && this._tempValueRep.end) {
        this._minTimeLimited = createDate(s, +this._tempValueRep.start);
      }
      if (s.maxRange !== UNDEFINED) {
        const maxLimited = hasTime
          ? this._tempValueRep.start! + s.maxRange
          : +addDays(createDate(s, this._tempValueRep.start!), s.maxRange) - 1;
        if (!this._maxLimited || +(makeDate(this._maxLimited, s, format) as Date) > maxLimited) {
          this._maxLimited = createDate(s, maxLimited);
        }
      }
    }

    // Set control options
    for (const control of this._controls) {
      const options: any = {
        ...other, // Pass all options
        display: 'inline',
        isOpen: s.isOpen || state.isOpen,
        max: this._maxLimited,
        min: this._minLimited,
      };

      // in case the range end can occur on an invalid date, and we are selecting the end date of the range
      // we need to allow that date to be selected
      // so we need to add it as a valid date to overwrite the invalid option for that day
      // we also need to extend the _maxLimited with an addition day since the inRangeInvalid option is also false
      // this is done above when we calculate the _maxLimited
      if (s.rangeEndInvalid && endSelection && this._nextInvalid) {
        options.valid = [...(options.valid || []), this._nextInvalid];
      }

      if (control.name === 'calendar') {
        options.min = this._minLimited ? getDateOnly(this._minLimited) : UNDEFINED;
        options.max = this._maxLimited ? getDateOnly(this._maxLimited) : UNDEFINED;
        options.selectRange = isRange;
        options.width = this._needsWidth ? PAGE_WIDTH * getPageNr(s.pages, state.maxPopupWidth) : UNDEFINED;

        if (s.calendarType === 'week' && calendarSize) {
          options.weeks = calendarSize;
        } else {
          options.size = calendarSize;
        }

        // If we have more than 2 pages, increase the max width of the popup (which defaults to 600px)
        const pages = s.pages === 'auto' ? 3 : s.pages || 1;
        this._maxWidth = s.maxWidth || (pages > 2 ? PAGE_WIDTH * pages : UNDEFINED);

        if (isRange) {
          const valueDate = this._getDate(this._tempValueRep) as Date[];
          const endDate = valueDate[1];
          if (endDate && s.exclusiveEndDates && !hasTime) {
            valueDate[1] = createDate(s, +endDate - 1);
          }
          const values = (valueDate as Date[])
            .filter((v) => v !== null) // filter out null values
            .map((v) => +getDateOnly(v)) // cut the time part and turn into timestamp (number) - for the distinct filter to work
            .filter((v, ind, arr) => arr.indexOf(v) === ind) // keep only distinct values
            // always pass date objects to subcomponents
            .map((v) => new Date(v)) as Date[];
          options.value = values;

          // Highlighted and hovered days
          if (s.rangeHighlight) {
            options.rangeStart = valueDate[0] && +getDateOnly(removeTimezone(valueDate[0]));
            options.rangeEnd = valueDate[1] && +getDateOnly(removeTimezone(valueDate[1]));
            options.onDayHoverIn = this._onDayHoverIn;
            options.onDayHoverOut = this._onDayHoverOut;
            if (select === 'preset-range') {
              if (state.hoverDate) {
                const { start, end } = getPresetRange(state.hoverDate, s);
                options.hoverStart = +start;
                options.hoverEnd = +end;
              }
            } else {
              if (activeSelect === 'end' && valueDate[0]) {
                options.hoverStart = options.rangeEnd || options.rangeStart;
                options.hoverEnd = state.hoverDate;
              }
              if (activeSelect === 'start' && valueDate[1] && this._renderControls) {
                options.hoverStart = state.hoverDate;
                options.hoverEnd = options.rangeStart || options.rangeEnd;
              }
            }
          }
        } else {
          options.selectMultiple = selectMultiple; // this should not be passed to the calendar in the case of the range
          options.value = this._getDate(this._tempValueRep);
        }

        const vals = isArray(options.value) ? options.value : [options.value];
        const min = options.min ? +options.min : -Infinity;
        const max = options.max ? +options.max : Infinity;
        let selected: number | undefined;
        for (const val of vals) {
          // Find first value between min and max
          if (!selected && val >= min && val <= max) {
            selected = +val;
          }
        }
        if (!selected && isRange && vals.length) {
          selected = +vals[0];
        }
        if (selected !== this._selectedDate || this._active === UNDEFINED || s.min !== prevS.min || s.max !== prevS.max) {
          this._selectedDate = selected;
          this._active = selected ? +getDateOnly(new Date(selected)) : constrain(this._active || +getDateOnly(new Date()), min, max);
        }

        const viewFormat = s.dateWheels || s.dateFormat!;
        const selectedView = /d/i.test(viewFormat)
          ? PAGE_VIEW
          : /m/i.test(viewFormat)
          ? YEAR_VIEW
          : /y/i.test(viewFormat)
          ? MULTI_YEAR_VIEW
          : PAGE_VIEW;

        options.active = this._active;
        options.onActiveChange = this._onActiveChange;
        options.onChange = this._onCalendarChange;
        options.onCellClick = this._onCellClick;
        options.onCellHoverIn = this._proxyHook;
        options.onCellHoverOut = this._proxyHook;
        options.onLabelClick = this._proxyHook;
        options.onPageChange = this._proxyHook;
        options.onPageLoaded = this._proxyHook;
        options.onPageLoading = this._proxyHook;
        options.selectView = selectedView;
      } else {
        const tempValueKeys = Object.keys(this._tempValueRep.date || {});
        // In case of the iOS theme we need the center color styling (light gray instead of darker gray),
        // if tabs are displayed or calendar is present (in top & bottom mode)
        options.displayStyle =
          (s.display === 'bottom' || s.display === 'top') && (this._hasCalendar || this._renderTabs) ? 'center' : s.display;
        options.mode = control.name; // date, time or datetime
        if ((control.name === 'time' || control.name === 'timegrid') && hasDate) {
          options.onChange = this._onTimePartChange;
          if (isRange) {
            // selectedTime needs to be set only initially, when there's no selection yet
            // it is updated from the change event in the _onTimePartChange
            const alreadySelectedOne = this._tempValueRep[activeSelect!];
            // we need to update the date part of the passed value, because the datetime validation
            // will take into account the date part as well (even if the control is time)
            let selectedTime;
            if (this._selectedTime) {
              if (!this._minTimeLimited || this._selectedTime > this._minTimeLimited) {
                selectedTime = this._selectedTime;
              } else {
                selectedTime = createDate(s, this._minTimeLimited);
                selectedTime.setHours(
                  this._selectedTime.getHours(),
                  this._selectedTime.getMinutes(),
                  this._selectedTime.getSeconds(),
                  this._selectedTime.getMilliseconds(),
                );
              }
            }
            // the current datetime is passed without the seconds and milliseconds,
            // so it won't stick into the time scroller innerParts variable
            const nowTrimmed = createDate(s);
            nowTrimmed.setSeconds(0, 0);
            this._selectedTime = alreadySelectedOne
              ? createDate(s, alreadySelectedOne)
              : selectedTime || (control.name === 'time' ? nowTrimmed : UNDEFINED);
            options.value = this._selectedTime;
          } else if (!s.selectMultiple) {
            const alreadyDate = (this._tempValueRep.date && this._tempValueRep.date[tempValueKeys[0]]) || this._selectedTime;
            this._selectedTime = options.value = alreadyDate;
          }
          options.min = this._minTimeLimited;
          options.max = this._maxLimited;
        } else {
          options.onChange = this._onDatetimeChange;
          if (isRange) {
            const n = this._tempValueRep[activeSelect!];
            const m = this._tempValueRep[notActive(activeSelect!)];
            options.value = n ? createDate(s, n) : m ? createDate(s, m) : null; // if there is already a selection of the not active,
            // we should start with that value
            // ^ Why? because when there is only the time picker the passed date object might have a different date than today.
            // But this will default to today in the Date component, so if you selected 8 PM yesterday on a only time picker, it
            // will allow to select the 7 PM as end time (which is not valid, bc. we should not take into account the Date only the time)
            if (activeSelect === 'end' && s.exclusiveEndDates && !hasTime) {
              options.value = createDate(s, +options.value - 1);
            }
          } else {
            const value = this._tempValueRep.date && this._tempValueRep.date[tempValueKeys[0]];
            let passed = value;
            if (value) {
              if (!hasTime) {
                passed = getDateOnly(value);
              }
            }
            options.value = passed || null;
          }
        }
        if (control.name === 'time' || control.name === 'timegrid') {
          // get the selected date or default
          const selectedOrDefault = options.value || constrainDate(new Date(), options.min, options.max);
          if (this._minTime) {
            // construct a minimum date from given time part and selected date's date part
            const minTime = this._minTime;
            const min = new Date(
              selectedOrDefault.getFullYear(),
              selectedOrDefault.getMonth(),
              selectedOrDefault.getDate(),
              minTime.getHours(),
              minTime.getMinutes(),
              minTime.getSeconds(),
              minTime.getMilliseconds(),
            );

            // override min option passed to the time control if bigger (more constraining) than already specified min option
            // Note: already specified min might be calculated from other factors like the range selection
            // end date can't be lass than the start and so on...
            if (!options.min || min > options.min) {
              options.min = min;
            }
          }
          if (this._maxTime) {
            // construct a maximum date from given time part and selected date's date part
            const maxTime = this._maxTime;
            const max = new Date(
              selectedOrDefault.getFullYear(),
              selectedOrDefault.getMonth(),
              selectedOrDefault.getDate(),
              maxTime.getHours(),
              maxTime.getMinutes(),
              maxTime.getSeconds(),
              maxTime.getMilliseconds(),
            );

            // override max option passed to the time control if smaller (more constraining) than already specified max option
            // Note: already specified max might be calculated from other factors like the range selection
            // maxRange option that limits the maximum value
            if (!options.max || max < options.max) {
              options.max = max;
            }
          }
        }
      }
      control.options = options;
    }

    this._prevStart = this._tempValueRep.start;
    this._prevStateValue = state.value;
  }

  protected _updated() {
    const s = this.s;
    if (this._shouldInitInputs) {
      this._resetInputs();
      if (s.select === 'range') {
        const startInput = s.startInput;
        if (startInput) {
          this._setupInput('start', startInput);
        }
        const endInput = s.endInput;
        if (endInput) {
          this._setupInput('end', endInput);
        }
        if (s.element && (this._startInput === s.element || this._endInput === s.element)) {
          this._shouldInitInput = false;
          clearTimeout(s.element.__mbscTimer);
        }
      }
      this._shouldInitInputs = false;
    }

    // we save the valueTextChange bc the base overwrites it and we won't know if there
    // was a value change or not. We need to populate the start/end inputs after the base _updated() call,
    // to overwrite the start value if the same input is used for the start and the initial input
    const valueTextChange = this._valueTextChange;
    super._updated();

    if (s.select === 'range' && valueTextChange) {
      const triggerChange = (inp: HTMLInputElement, val: string) => {
        inp.value = val;
        setTimeout(() => {
          this._preventChange = true;
          trigger(inp, INPUT);
          trigger(inp, CHANGE);
        });
      };
      if (this._startInput) {
        triggerChange(this._startInput, this._getValueText('start'));
      }
      if (this._endInput) {
        triggerChange(this._endInput, this._getValueText('end'));
      }
    }
  }

  protected _onEnterKey(args: any) {
    if (!this._selectionNotReady()) {
      // if selection is ready do the default behavior
      super._onEnterKey(args);
    }
  }

  protected _setupInput(i: 'start' | 'end', input: any) {
    getNativeElement(input, (inp: HTMLInputElement) => {
      const resetElement = initPickerElement(inp, this, this._onInputChangeRange, this._onInputClickRange);

      if (i === 'start') {
        this._startInput = inp;
        this._resetStartInput = resetElement;
      } else {
        this._endInput = inp;
        this._resetEndInput = resetElement;
      }

      const val = this._getValueText(i);
      const changed = val !== inp.value;
      inp.value = val;
      if (changed) {
        setTimeout(() => {
          this._preventChange = true;
          trigger(inp, INPUT);
          trigger(inp, CHANGE);
        });
      }
    });
  }

  protected _destroy() {
    // clean up after start/end inputs
    this._resetInputs();
    super._destroy();
  }

  protected _setHeader() {
    const s = this.s;
    if (s.selectCounter && s.selectMultiple) {
      const count = Object.keys((this._tempValueRep && this._tempValueRep.date) || {}).length;
      this._headerText = (count > 1 ? s.selectedPluralText! || s.selectedText! : s.selectedText!).replace(/{count}/, '' + count);
    } else {
      super._setHeader();
    }
  }

  // TODO: check if common parts with date scroller validation could be extracted
  protected _validate() {
    if (this._max! <= this._min!) {
      return;
    }

    const s = this.s;
    const min = this._min ? +this._min : -Infinity;
    const max = this._max ? +this._max : Infinity;

    if (s.select === 'date') {
      const values = this._tempValueRep.date!;
      // In case of multiple select we don't validate the values
      if (!s.selectMultiple) {
        // Iterate through all selected dates and validate them
        for (const key of Object.keys(values)) {
          const d = values[key];
          const validated = getClosestValidDate(d, s, min, max);

          if (+validated !== +d) {
            delete values[key];
            values[+getDateOnly(validated)] = validated;
          }
        }
      }
    } else {
      // range
      const range = this._getDate(this._tempValueRep) as [Date | null, Date | null];

      // Constrain the range between the min and max values
      let [startDate, endDate] = range;

      if (startDate) {
        startDate = getClosestValidDate(startDate, s, min, max);
        // also get the next invalid date in case there can't be any invalids in the range
        // for later validating the endDate
        if (!s.inRangeInvalid && (!this._prevStart || this._prevStart !== +startDate)) {
          this._nextInvalid = s.valid
            ? addDays(getLatestOccurrence(s.valid, startDate, s), 1)
            : getNextOccurrence(s.invalid || [], startDate, s);
        }
      }

      if (endDate) {
        // validate the end using the inRangeInvalid an the rangeEndInvalid options
        if (!s.inRangeInvalid && this._nextInvalid && this._nextInvalid <= endDate) {
          endDate = s.rangeEndInvalid ? this._nextInvalid : addDays(this._nextInvalid, -1);
        } else {
          endDate = getClosestValidDate(endDate, s, min, max); // otherwise get the next valid date
        }
      }

      if (startDate && endDate && startDate > endDate) {
        if (this._activeSelect === 'end') {
          startDate = endDate;
        } else {
          endDate = startDate;
        }
      }

      if (startDate) {
        this._prevStart = this._tempValueRep.start = +startDate;
      }

      if (endDate) {
        this._tempValueRep.end = +endDate;
      }
    }
  }

  protected _copy(value: any): any {
    const date = value.date ? { ...value.date } : value.date;
    return {
      ...value,
      date, // overwrites the array with a new one, if it exists
    };
  }

  /**
   * Formats the value representation to a string
   * IMPORTANT: The order of the dates in the formatted string is definition order!
   * @param valueRep The value representation object
   */
  protected _format(valueRep: IDValueRepresentation): string {
    const s = this.s;
    const ret: string[] = [];
    if (!s) {
      return '';
    }
    if (s.select === 'date') {
      const vRep = valueRep.date;
      for (const i in vRep) {
        if (vRep[i] !== UNDEFINED && vRep[i] !== null) {
          ret.push(formatDate(this._valueFormat, vRep[i], s));
        }
      }
      return s.selectMultiple ? ret.join(', ') : ret[0];
    } else {
      // range selection
      if (valueRep.start) {
        ret.push(formatDate(this._valueFormat, createDate(s, valueRep.start), s));
      }
      if (valueRep.end) {
        if (!ret.length) {
          // only end date is selected
          ret.push('');
        }
        const end = createDate(s, valueRep.end - (s.exclusiveEndDates && !this._hasTime ? 1 : 0));
        ret.push(formatDate(this._valueFormat, end, s));
      }
      this._tempStartText = ret[0] || '';
      this._tempEndText = ret[1] || '';
      return ret.join(RANGE_SEPARATOR);
    }
  }

  // tslint:disable-next-line: variable-name
  protected _parse(value: any, fromInput?: boolean): IDValueRepresentation {
    const s = this.s;
    const ret: IDValueRepresentation = {};
    const isRange = s.select !== 'date';
    const isMultiple = s.selectMultiple;
    let values = [];

    if (isEmpty(value)) {
      const def = s.defaultSelection;
      value =
        isMultiple || isRange
          ? // Range & multiple select
            def
          : // Single selection, only allow explicit null, otherwise default to now
          // Also in live mode when, the value is empty, we parse to NULL, and pass the defaultSelection
          // to the inner controls, so they make the decision on what to show to the user.
          // Otherwise the current value can't be set by clicking on it.
          // def === null || !this._hasCalendar || (this._live && s.display !== 'inline') ? null : (def || new Date());
          def === null || (this._live && s.display !== 'inline')
          ? null
          : def || new Date();
    }

    if (isString(value) && (isRange || isMultiple)) {
      values = value.split(isRange ? RANGE_SEPARATOR : ',');
    } else if (isArray(value)) {
      values = value;
    } else if (value && !isArray(value)) {
      values = [value];
    }

    if (isRange) {
      const [start, end] = values;
      const startDate = makeDate(start, s, this._valueFormat, this._iso);
      const endDate = makeDate(end, s, this._valueFormat, this._iso);
      ret.start = startDate ? +startDate : UNDEFINED;
      ret.end = endDate ? +endDate : UNDEFINED;
    } else {
      ret.date = {};
      for (const val of values) {
        if (!isEmpty(val)) {
          let date = makeDate(val, s, this._valueFormat, this._iso, fromInput);
          if (date) {
            if (fromInput) {
              date = addTimezone(s, date);
            }
            const key = +getDateOnly(date); // we need this for ranges that are less or equal to one day
            ret.date[key] = date;
            if (this._hasTime) {
              this._selectedTime = new Date(date);
            }
          }
        }
      }
    }

    return ret;
  }

  protected _getDate(value: IDValueRepresentation): null | Date | Array<Date | null> {
    const s = this.s;
    const isRange = s.select !== 'date';

    // range picker
    if (isRange) {
      const start = value.start ? createDate(s, value.start) : null;
      const end = value.end ? createDate(s, value.end) : null;
      if (!start && !end) {
        return [];
      }
      return [start, end];
    }

    // multi-select date

    if (s.selectMultiple) {
      const valueArray: Date[] = [];
      const dates = value.date;
      if (dates) {
        for (const v of Object.keys(dates)) {
          valueArray.push(createDate(s, +v));
        }
      }
      return valueArray;
    }

    // single-select date

    const valueKeys = Object.keys(value.date || {});
    if (!valueKeys.length) {
      return null;
    }
    return createDate(s, value.date![valueKeys[0]]);
  }

  /**
   * Returns the value from the value representation
   * NOTE: In the case of the range, if the start date is selected only, the end will be null
   * @param value The value representation for the datepicker
   */
  protected _get(value: IDValueRepresentation): MbscDatepickerValue {
    const s = this.s;
    const valueFormat = this._valueFormat;
    const isoParts = this._iso;
    const valueDate = this._getDate(value);

    if (isArray(valueDate)) {
      return valueDate.map((date) => (date ? returnDate(date, s, valueFormat, isoParts, this._hasTime) : null));
    }
    if (valueDate === null) {
      return null;
    }
    return returnDate(valueDate, s, valueFormat, isoParts, this._hasTime);
  }

  protected _onClosed() {
    this._active = this._activeSelect = UNDEFINED;
    // if there's a timegrid, we should also clear the time part of the selection
    if (this._hasTimegrid) {
      this._selectedTime = UNDEFINED;
    }
  }

  protected _onOpen() {
    this._newSelection = true; // used by the range selection only
  }

  // tslint:disable-next-line: variable-name
  private _onInputClickRange = (ev: any) => {
    const inp = ev.target;
    const activate = inp === this._startInput || this._renderControls ? 'start' : 'end';
    this._setActiveSelect(activate);
  };

  // tslint:disable-next-line: variable-name
  private _onInputChangeRange = (ev: any) => {
    const startInput = this._startInput;
    const endInput = this._endInput;
    const value = (startInput ? startInput.value : '') + (endInput && endInput.value ? RANGE_SEPARATOR + endInput.value : '');
    this._onInputChange(ev, value);
  };

  private _resetInputs() {
    if (this._resetStartInput) {
      this._resetStartInput();
      this._resetStartInput = UNDEFINED;
    }
    if (this._resetEndInput) {
      this._resetEndInput();
      this._resetEndInput = UNDEFINED;
    }
  }

  /** The formatted end value in the case of the range picker */
  private _getValueText(input: 'start' | 'end'): string {
    return this._valueText.split(RANGE_SEPARATOR)[input === 'start' ? 0 : 1] || '';
  }

  /**
   * Checks if the temp selection is NOT ready yet for set
   * In the case of the range picker the selection is not ready when
   * - no value is selected OR
   * - only one value is selected and the labels are shown
   * If the labels are not shown, we allow the selection in the case of date control or the calendar together with
   * time - there's no way to switch to second value otherwise.
   */
  private _selectionNotReady(): boolean {
    let notReady = false;
    if (this.s.select === 'range') {
      const val = ((this._get(this._tempValueRep || {}) as any[]) || []).filter((v) => v); // filter out null/undefined
      notReady = !val.length; // no value selected
      if (!notReady) {
        if (this._hasCalendar && !this._hasTime) {
          notReady = val.length < 2; // using the calendar - both values have to be selected
        } else {
          if (this._renderControls) {
            // start/end selection labels shown - both values have to be selected
            notReady = val.length < 2;
          } else {
            // start/end selection labels hidden - we let only one value to be selected if it's the active selection
            notReady = !this._tempValueRep[this._activeSelect!];
          }
        }
      }
    }
    return notReady;
  }

  /** Sets the _activeSelect property and triggers the 'onActiveDateChange' event if the active select changed */
  private _setActiveSelect(active: 'start' | 'end', timeout?: true) {
    if (this._activeSelect !== active) {
      if (timeout) {
        // TODO: What if we always do it with timeout?
        setTimeout(() => this._hook<MbscDatepickerActiveDateChangeEvent>('onActiveDateChange', { active }));
      } else {
        this._hook<MbscDatepickerActiveDateChangeEvent>('onActiveDateChange', { active });
      }
    }
    this._activeSelect = active;
  }
}
