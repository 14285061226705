import React,{ useState, useEffect, useRef } from "react";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";

import { alert } from "@mobiscroll/react";
import { OutlookData, initalOutlookData } from "./_model";
import { getGeneralSetting } from "../../../../../_metronic/requests/GeneralSettingRequest";
import { useQuery } from "react-query";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { getTooltipData } from "../../../superadmin/hint-tour/core/_request";
import { useAuth } from "../../../auth/core/AuthCognito";
import { getCurrentUserDetails, updateTooltipVisibilityByPage } from "../../common/_requests";


const dynamicList = [
  { label: 'Task/ticket title', link: '/task-ticket-title' },
  { label: 'Ticket description', link: '/ticket-description' },
  { label: 'Ticket create date', link: '/ticket-create-date' },
  { label: 'Task start date', link: '/task-start-date' },
  { label: 'Task start date/time', link: '/task-start-date-time' },
  { label: 'Task end date', link: '/task-end-date' },
  { label: 'Ticket/task number', link: '/ticket-task-number' },
  { label: 'Ticket/task number with Autotask link', link: '/ticket-task-number-with-autotask-link' },
  { label: 'Task/ticket estimated hours', link: '/task-ticket-estimated-hours' },
  { label: 'Service call description', link: '/service-call-description' },
  { label: 'Service call status', link: '/service-call-status' },
  { label: 'Location', link: '/location' },
  { label: 'Line of Business', link: '/line-of-business' },
  { label: 'Company name', link: '/company-name' },
  { label: 'Project name', link: '/project-name' },
  { label: 'Phase name', link: '/phase-name' },
  { label: 'Project lead', link: '/project-lead' },
  { label: 'Primary resource', link: '/primary-resource' },
  { label: 'Status', link: '/status' },
  { label: 'Work type', link: '/work-type' },
  { label: 'Issue and sub-issue type', link: '/issue-and-sub-issue-type' },
  { label: 'Priority', link: '/priority' },
];

const outlookSchema = Yup.object().shape({
  clientSecret: Yup.string()
    .nullable()
    .required("Client Secret is required")
    .test(
      "no-leading-or-trailing-space",
      "Blank spaces at the beginning or end are not allowed",
      (value) => {
        if (value) {
          return value.trim() === value;
        }
        return true;
      }
    )
    .matches(/^(\S+$)/g, "Client Secret cannot contain blankspaces"),
  clientId: Yup.string()
    .nullable()
    .required("Client Id is required")
    .test(
      "no-leading-or-trailing-space",
      "Blank spaces at the beginning or end are not allowed",
      (value) => {
        if (value) {
          return value.trim() === value;
        }
        return true;
      }
    )
    .matches(/^(\S+$)/g, "Client Id cannot contain blankspaces"),
  tenentId: Yup.string()
    .nullable()
    .required("Tenant Id is required")
    .test(
      "no-leading-or-trailing-space",
      "Blank spaces at the beginning or end are not allowed",
      (value) => {
        if (value) {
          return value.trim() === value;
        }
        return true;
      }
    )
    .matches(/^(\S+$)/g, "Tenant Id cannot contain blankspaces"),
  defaultOutlookEmail: Yup.string()
      .nullable()
      .required('Email is required')
      .email('Enter valid email'),
    
      type_task: Yup.string().nullable(),
      title_task: Yup.string().nullable().max(255, "Task title cannot exceed 255 characters"),
      description_task: Yup.string().nullable(),
    
      type_ticket: Yup.string().nullable(),
      title_ticket: Yup.string().nullable().max(255, "Ticket title cannot exceed 255 characters"),
      description_ticket: Yup.string().nullable(),
    
      type_serviceCall: Yup.string().nullable(),
      title_serviceCall: Yup.string().nullable().max(255, "Service call title cannot exceed 255 characters"),
      description_serviceCall: Yup.string().nullable(),
});

const OutlookSync = () => {

  const {
    data: generalSetting,
  } = useQuery(
    `general-resource-setting`,
    async () => {
      return await getGeneralSetting("");
    },
    {
      cacheTime: 0,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  const [showClientId, setShowClientId] = useState(true);
  const [showClientSecret, setShowClientSecret] = useState(true);
  const [showTenentId, setShowTenentId] = useState(true);
  const locationShow = generalSetting?.isLocation ? true : false;

  const inputRefs = {
    title_task: useRef(null),
    title_ticket: useRef(null),
    title_serviceCall: useRef(null),
    description_task: useRef(null),
    description_ticket: useRef(null),
    description_serviceCall: useRef(null),
  };
  const [selectedTaskLabel, setSelectedTaskLabel] = useState('');
  const [selectedTicketLabel, setSelectedTicketLabel] = useState('');
  const [selectedServiceCallLabel, setSelectedServiceCallLabel] = useState('');
  const [selectedTaskDescription, setSelectedTaskDescription] = useState('');
  const [selectedTicketDescription, setSelectedTicketDescription] = useState('');
  const [selectedServiceCallDescription, setSelectedServiceCallDescription] = useState('');
  const [focusedInput, setFocusedInput] = useState<any>(null);

  const organization = localStorage.getItem("organizationID");
  const organizationId = organization ? organization : null;
  const formik = useFormik<OutlookData>({
    initialValues: initalOutlookData,
    validationSchema: outlookSchema,
    onSubmit: (values) => {
      handleDataPosting(values);
    },
    enableReinitialize: true,
  });
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);

  // fetches alredy present data if it is there , if not then it will show empty fields
  useEffect(() => {
    const fetchTokenDataIfExist = async () => {
      try {
        const request = await axios.get(
          `${process.env.REACT_APP_API_URL}outlook/get-outlook-data/${organizationId}`
        );
        if (request.data.data) {
          if (request.data.data && !isInitialDataLoaded) {
            const newData = {
              enableOutLookIntegration:
                request.data.data.outlook_sync_is_active,
              clientId: request.data.data.outlook_client_id,
              clientSecret: request.data.data.outlook_client_secret,
              tenentId: request.data.data.outlook_tenant_id,
              defaultOutlookEmail: request.data.data.outlook_default_email,
              type_task: request.data.data.outlook_type_task , 
              title_task: request.data.data.outlook_title_task,
              description_task:  request.data.data.outlook_description_task,
              type_ticket: request.data.data.outlook_type_ticket,
              title_ticket: request.data.data.outlook_title_ticket,
              description_ticket: request.data.data.outlook_description_ticket,
              type_serviceCall: request.data.data.outlook_type_serviceCall,
              title_serviceCall: request.data.data.outlook_title_serviceCall,
              description_serviceCall: request.data.data.outlook_description_serviceCall
            };
            setIsInitialDataLoaded(true);
            setShowClientId(false);
            setShowTenentId(false);
            setShowClientSecret(false);
            formik.resetForm({ values: newData });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    //call fetchData
    fetchTokenDataIfExist();
  }, [formik]);


  //saves the data to the api and update the user
  const handleDataPosting = async (values: any) => {
    try {
      const request = await axios.post(
        `${process.env.REACT_APP_API_URL}outlook/`,
        {
          outlook_sync_is_active: values.enableOutLookIntegration,
          outlook_client_id: values.clientId,
          outlook_client_secret: values.clientSecret,
          outlook_tenant_id: values.tenentId,
          organizationId: organizationId,
          outlook_default_email: values.defaultOutlookEmail,
          outlook_type_task: values.type_task , 
          outlook_title_task: values.title_task,
          outlook_description_task:  values.description_task,
          outlook_type_ticket: values.type_ticket,
          outlook_title_ticket: values.title_ticket,
          outlook_description_ticket: values.description_ticket,
          outlook_type_serviceCall: values.type_serviceCall,
          outlook_title_serviceCall: values.title_serviceCall,
          outlook_description_serviceCall: values.description_serviceCall
        }
      );
      if (request.status == 200) {
        let msg;
        if (values.enableOutLookIntegration == true) {
          msg = `Outlook integration has been successfully enabled!`;
        } else {
          msg = `Changes saved successfully!`;
        }
        alert({
          message: msg,
          callback: function () {
            window.location.reload();
          },
        });
      }
    } catch (error: any) {
      alert({
        message: error.response.data.message,
      });
    }
  };

  const handleLabelClick = (label: any) => {
    const inputRef = inputRefs[focusedInput];
  
    if (inputRef && inputRef.current) {
      const cursorPosition = inputRef.current.selectionStart;
      const currentValue = formik.values[focusedInput];
      const newValue = currentValue ? currentValue.slice(0, cursorPosition) + label +currentValue.slice(cursorPosition) : label;
  
      formik.setFieldValue(focusedInput, newValue);
  
      // Set the cursor position after the inserted label
      const newCursorPosition = cursorPosition + label.length;
      inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
    }
  
    // Set the corresponding state for the selected label
    switch (focusedInput) {
      case 'title_task':
        setSelectedTaskLabel(label);
        break;
      case 'title_ticket':
        setSelectedTicketLabel(label);
        break;
      case 'title_serviceCall':
        setSelectedServiceCallLabel(label);
        break;
      case 'description_task':
        setSelectedTaskDescription(label);
        break;
      case 'description_ticket':
        setSelectedTicketDescription(label);
        break;
      case 'description_serviceCall':
        setSelectedServiceCallDescription(label);
        break;
      default:
        break;
    }
  };
  

  const handleFocus = (inputName: any) => {
    setFocusedInput(inputName);
  };

  function getMatchingInputRef(inputRefs: any, focusedInput: any) {
    for (const key in inputRefs) {
      if (key === focusedInput) {
        return inputRefs[key];
      }
    }
    return null;
  }
 
  useEffect(() => {
    const handleClickOutside = (event: any) => {
 
      const matchingInputRef = getMatchingInputRef(inputRefs, focusedInput);
 
      if (matchingInputRef !== null) {
        if (matchingInputRef?.current && !matchingInputRef?.current.contains(event.target)) {
          setFocusedInput(null);
        }
      }
    };
 
    document.addEventListener('click', handleClickOutside);
 
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [focusedInput, inputRefs]);
  
  const filteredList = !locationShow
  ? dynamicList.filter(item => item.label !== 'Location')
  : dynamicList;

  const renderOutlookSyncSettingsTooltip = (
    <Popover id="renderOutlookSyncSettingsTooltip">
      <div className="p-5 bg-white card">
        <div>
          Configure your Outlook sync by entering your credentials below.
          <a className="text-decoration-underline ms-2"
            href="https://knowledgebase.proxuma.io/article/setup-outlook-sync-with-proxuma"
            target="_blank"
          >
            Click here to learn more about setting up your Outlook credentials.
          </a>
        </div>
      </div>
    </Popover>
  )
  const renderAppointmentLayoutTooltip = (
    <Popover id="renderAppointmentLayoutTooltip">
      <div className="p-5 bg-white card">
        <div>
        Configure how you want your planned items/service calls to appear in your Outlook Calendar.
          <a className="text-decoration-underline ms-2"
            href="https://knowledgebase.proxuma.io/article/outlook-sync-settings"
            target="_blank"
          >
            Learn more.
          </a>
        </div>
      </div>
    </Popover>
  )
  const { data: tooltipData } = useQuery(
    "tooltip-Data",
    async () => {
      return await getTooltipData();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );
  const { data: loggedUser } = useQuery(
    'logged-user',
    async () => {
      return await getCurrentUserDetails(_id)
    },
    {
      cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: false
    }
  )

  const { currentUser } = useAuth()
  const  _id  = currentUser?.data?.user?._id
  const [enabled, setEnabled] = useState<boolean>(true);
  
  const tooltipVisibility = {
    showTooltipVisibility: {
      SettingsEmails: false,
    },
  };
  const onExit = async () => {
    console.log('onexit Outlook');
    setEnabled(false);
    await updateTooltipVisibilityByPage(_id, tooltipVisibility);
  };
  return (
    <div
      id="kt_content_container"
      className={
        "container container-fluid position-relative px-0 overflow-auto notifyHeight"
      }
    >
      <div id="kt_general_setting_details" className={"px-4 py-6"}>
        <form className="form" onSubmit={formik.handleSubmit}>
          <div className="row mx-0">
            <div className="col-12 mb-3">
              {/* Card  starts here */}
              <div className="card h-100" id="outlook-sync-settings">
                {/* Card header starts here */}
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="card-title">
                    <h3 className="text-dark fw-bold fs-18 m-0">
                      Outlook Sync Settings
                    </h3>
                    <div className="h-24px d-flex align-items-center">
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement="right"
                        overlay={renderOutlookSyncSettingsTooltip}
                      >
                        <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Save Changes
                  </button>
                </div>
                {/* Card header ends here */}
                {/* Card body starts here  */}
                <div className="card-body  border-top py-5 overflow-auto mh-100">
                  <div className="row mb-6">
                    <label className="col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                      Enable Outlook Integration
                    </label>
                    <div className="d-flex fv-row fv-row col-md-8">
                      <div className="col-lg-8 d-flex align-items-center">
                        <div className="form-check form-check-dark form-switch fv-row">
                          <input
                            className="form-check-input w-45px h-30px"
                            type="checkbox"
                            {...formik.getFieldProps(
                              "enableOutLookIntegration"
                            )}
                            checked={formik.values.enableOutLookIntegration}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-6">
                    <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                      Client ID{" "}
                    </label>

                    <div className="fv-row col-md-8">
                      <div className="position-relative">
                        <input
                          type={showClientId ? "text" : "password"}
                          className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                          placeholder="Enter Client ID"
                          style={{ width: "100%" }}
                          {...formik.getFieldProps("clientId")}
                        />
                        {showClientId ? (
                          <i
                            className="bi bi-eye-slash-fill fs-2x position-absolute top-50 translate-middle me-4 end-0"
                            onClick={() => setShowClientId(!showClientId)}
                          ></i>
                        ) : (
                          <i
                            className="bi bi-eye fs-2x bi bi-eye-slash-fill fs-2x position-absolute top-50 translate-middle me-4 end-0"
                            onClick={() => setShowClientId(!showClientId)}
                          ></i>
                        )}
                      </div>
                      {formik.touched.clientId && formik.errors.clientId && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block fs-8">
                            {formik.errors.clientId}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row mb-6">
                    <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                      Tenant ID
                    </label>

                    <div className="fv-row col-md-8">
                      <div className="position-relative">
                        <input
                          type={showTenentId ? "text" : "password"}
                          className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                          placeholder="Enter Tenant ID"
                          style={{ width: "100%" }}
                          {...formik.getFieldProps("tenentId")}
                        />

                        {showTenentId ? (
                          <i
                            className="bi bi-eye-slash-fill fs-2x position-absolute top-50 translate-middle me-4 end-0"
                            onClick={() => setShowTenentId(!showTenentId)}
                          ></i>
                        ) : (
                          <i
                            className="bi bi-eye fs-2x position-absolute top-50 translate-middle me-4 end-0"
                            onClick={() => setShowTenentId(!showTenentId)}
                          ></i>
                        )}
                      </div>
                      {formik.touched.tenentId && formik.errors.tenentId && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block fs-8">
                            {formik.errors.tenentId}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row mb-6">
                    <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                      Client Secret{" "}
                    </label>

                    <div className="fv-row col-md-8">
                      <div className="position-relative">
                        <input
                          type={showClientSecret ? "text" : "password"}
                          className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                          placeholder="Enter Client Secret"
                          style={{
                            width: "100%",
                          }}
                          {...formik.getFieldProps("clientSecret")}
                        />
                        {showClientSecret ? (
                          <i
                            className="bi bi-eye-slash-fill fs-2x position-absolute top-50 translate-middle me-4 end-0"
                            onClick={() =>
                              setShowClientSecret(!showClientSecret)
                            }
                          ></i>
                        ) : (
                          <i
                            className="bi bi-eye fs-2x position-absolute top-50 translate-middle me-3 end-0"
                            onClick={() =>
                              setShowClientSecret(!showClientSecret)
                            }
                          ></i>
                        )}
                      </div>
                      {formik.touched.clientSecret &&
                        formik.errors.clientSecret && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block fs-8">
                              {formik.errors.clientSecret}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="row mb-6">
                    <label className="col-form-label required fw-bold fs-16 text-dark mw-300px mw-xxl-400px">
                      Default Email Address for Planning Outlook Items (Organizer){" "}
                    </label>

                    <div className="fv-row col-md-8">
                      <div className="position-relative">
                        <input
                          type='text'
                          className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                          placeholder="Default Email Address for Planning Outlook Items (Organizer)"
                          style={{
                            width: "100%",
                          }}
                          {...formik.getFieldProps("defaultOutlookEmail")}
                        />
                      </div>
                      {  formik.touched.defaultOutlookEmail &&
                         formik.errors.defaultOutlookEmail && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block fs-8">
                              {formik.errors.defaultOutlookEmail}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                {/* Card body ends here  */}
              </div>
            </div>
          </div>
        </form>
      </div>
      
      <div id="kt_general_setting_details" className={"px-4 py-6"}>
        <form className="form" onSubmit={formik.handleSubmit}>
          <div className="row mx-0">
            <div className="col-12 mb-3" id="appointment-layout">
              {/* Card  starts here */}
              <div className="card h-100">
                {/* Card header starts here */}
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="card-title">
                    <h3 className="text-dark fw-bold fs-18 m-0">
                      Appointment Layout
                    </h3>
                    <div className="h-24px d-flex align-items-center">
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement="right"
                        overlay={renderAppointmentLayoutTooltip}
                      >
                        <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
                <div className="row"> 
                  <div className="col-5 max-w-900px">
                    <div className="card-body  border-bottom py-5 pb-7 mb-2 overflow-auto mh-100">
                      <div className="row mb-1">
                        <label className="col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-130px">
                          Type
                        </label>

                        <div className="w-auto flex-fill">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                              placeholder="Task"
                              value="Task"
                                                         
                              disabled
                            />
                          </div>
                          {formik.touched.type_task && formik.errors.type_task && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block fs-8">
                                {formik.errors.type_task}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-1">
                        <label className="col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-130px">
                          Title
                        </label>

                        <div className="w-auto flex-fill">
                          <div className="position-relative">
                            <input
                              ref={inputRefs.title_task}
                              type="text"
                              className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                              placeholder="Input field for variables and static text"
                              value={formik.values.title_task}
                              onFocus={() => handleFocus('title_task')}
                              onChange={(e) => formik.setFieldValue('title_task', e.target.value)}
                              
                            />
                          </div>
                          {formik.touched.title_task && formik.errors.title_task && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block fs-8">
                                {formik.errors.title_task}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-1">
                        <label className="col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-130px">
                          Description
                        </label>

                        <div className="w-auto flex-fill">
                          <div className="position-relative">
                            <textarea
                              ref={inputRefs.description_task}
                              rows={6}
                              className="form-control fs-16 fw-normal p-0 px-4 bg-transparent border-graybor"
                              placeholder="Input field for variables and static text"
                              value={formik.values.description_task}
                              onFocus={() => handleFocus('description_task')}
                              onChange={(e) => formik.setFieldValue('description_task', e.target.value)}
                            />
                          </div>
                          {formik.touched.description_task &&
                            formik.errors.description_task && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block fs-8">
                                  {formik.errors.description_task}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>

                    <div className="card-body  border-bottom py-5 pb-7 mb-2 overflow-auto mh-100">
                      <div className="row mb-1">
                        <label className="col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-130px">
                          Type
                        </label>

                        <div className="w-auto flex-fill">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                              placeholder="Ticket"
                              value="Ticket"
                             
                              disabled
                            />
                          </div>
                          {formik.touched.type_ticket && formik.errors.type_ticket && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block fs-8">
                                {formik.errors.type_ticket}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-1">
                        <label className="col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-130px">
                          Title
                        </label>

                        <div className="w-auto flex-fill">
                          <div className="position-relative">
                            <input
                              ref={inputRefs.title_ticket}
                              type="text"
                              className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                              placeholder="Input field for variables and static text"
                              value={formik.values.title_ticket}
                              onFocus={() => handleFocus('title_ticket')}
                              onChange={(e) => formik.setFieldValue('title_ticket', e.target.value)}
                            />
                          </div>
                          {formik.touched.title_ticket && formik.errors.title_ticket && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block fs-8">
                                {formik.errors.title_ticket}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-1">
                        <label className="col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-130px">
                          Description
                        </label>

                        <div className="w-auto flex-fill">
                          <div className="position-relative">
                            <textarea
                              ref={inputRefs.description_ticket}
                              rows={6}
                              className="form-control fs-16 fw-normal p-0 px-4 bg-transparent border-graybor"
                              placeholder="Input field for variables and static text"
                              value={formik.values.description_ticket}
                              onFocus={() => handleFocus('description_ticket')}
                              onChange={(e) => formik.setFieldValue('description_ticket', e.target.value)}
                            />
                          </div>
                          {formik.touched.description_ticket &&
                            formik.errors.description_ticket && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block fs-8">
                                  {formik.errors.description_ticket}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>

                    <div className="card-body  py-5 overflow-auto mh-100">
                      <div className="row mb-1">
                        <label className="col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-130px">
                          Type
                        </label>

                        <div className="w-auto flex-fill">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                              placeholder="Service call with multiple tickets and/or tasks"
                              value='Service call with multiple tickets and/or tasks'
                              disabled
                            />
                          </div>
                          {formik.touched.type_serviceCall && formik.errors.type_serviceCall && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block fs-8">
                                {formik.errors.type_serviceCall}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-1">
                        <label className="col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-130px">
                          Title
                        </label>

                        <div className="w-auto flex-fill">
                          <div className="position-relative">
                            <input
                              ref={inputRefs.title_serviceCall}
                              type="text"
                              className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                              placeholder="Input field for variables and static text"
                              value={formik.values.title_serviceCall}
                              onFocus={() => handleFocus('title_serviceCall')}
                              onChange={(e) => formik.setFieldValue('title_serviceCall', e.target.value)}
                            />
                          </div>
                          {formik.touched.title_serviceCall && formik.errors.title_serviceCall && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block fs-8">
                                {formik.errors.title_serviceCall}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-1">
                        <label className="col-form-label fw-bold fs-16 text-dark mw-300px mw-xxl-130px">
                          Description
                        </label>

                        <div className="w-auto flex-fill">
                          <div className="position-relative">
                            <textarea
                              ref={inputRefs.description_serviceCall}
                              rows={6}
                              className="form-control fs-16 fw-normal p-0 px-4 bg-transparent border-graybor"
                              placeholder="Input field for variables and static text"
                              value={formik.values.description_serviceCall}
                              onFocus={() => handleFocus('description_serviceCall')}
                              onChange={(e) => formik.setFieldValue('description_serviceCall', e.target.value)}
                            />
                          </div>
                          {formik.touched.description_serviceCall &&
                            formik.errors.description_serviceCall && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block fs-8">
                                  {formik.errors.description_serviceCall}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-7 mb-10">
                    {/* Card  starts here */}
                    <div className="card rounded-50 pe-20 d-inline-block p-10 mt-5" style={{ border: '1px solid #DADCE0' }}>
                      {/* Card header starts here */}
                      <div
                        className="card-header border-0 min-h-1px px-0"
                      >
                        <div className="card-title m-0 mb-5">
                          <h2 className="fs-40">
                          Available Variables
                          </h2>
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <div className="row mb-1">
                          <ul className="list-unstyled">
                            {filteredList.map((item, index) => (
                              <li key={index} onClick={() => handleLabelClick(`[${item.label}]`)} className="fw-bold fs-16 text-dark mw-300px mw-xxl-400px cursor-pointer py-1">
                                &#91;{item.label}&#93;
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>       

              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OutlookSync;
