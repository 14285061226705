/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import '../../../../_metronic/assets/sass/login.scss';

const APP_URL:any = process.env.REACT_APP_HOST_URL;

export function LoggedOut() {
  
  localStorage.clear();
    let urlParams = new URLSearchParams(window.location.search);
    //  href="`http://${}.localhost/3000/auth`"
  
    let mainUrl = APP_URL.split('www.');
    useEffect(() => {
    // window.location.href = `http://${urlParams.get('params')}.localhost:3000/auth`;
     window.location.href = `https://${urlParams.get('params')}.${mainUrl[1]}/auth`;
    }, []);
    
    
  

return (
  <>
     <div className="w-100 w-sm-400px">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
    </div>
  </>
);



}