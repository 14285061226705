import { MbscCalendarEvent } from '../shared/calendar-view/calendar-view.types';
import {
  addDays,
  createDate,
  formatDatePublic as formatDate,
  getDateOnly,
  getDateStr,
  getDayDiff,
  getFirstDayOfWeek,
  IDatetimeProps,
  ISO_8601_TIME,
  isTime,
  makeDate,
} from './datetime';
import { floor, isArray, isString, UNDEFINED } from './misc';
import { MbscRecurrenceRule } from './recurrence.types.public';

interface IOccurrence {
  // Occurrence number
  i: number;
  // Occurrence date
  d: Date;
}

// tslint:disable no-non-null-assertion
// tslint:disable no-inferrable-types

const WEEK_DAYNAMES: { [key: number]: string } = { 0: 'SU', 1: 'MO', 2: 'TU', 3: 'WE', 4: 'TH', 5: 'FR', 6: 'SA' };
const WEEK_DAYS: { [key: string]: number } = { SU: 0, MO: 1, TU: 2, WE: 3, TH: 4, FR: 5, SA: 6 };

const RULE_KEY_MAP: { [key: string]: string } = {
  byday: 'weekDays',
  bymonth: 'month',
  bymonthday: 'day',
  bysetpos: 'pos',
  dtstart: 'from',
  freq: 'repeat',
  wkst: 'weekStart',
};

/** @hidden */
function addMultiDayEvent(obj: { [key: string]: any }, item: any, s: IDatetimeProps, overwrite?: boolean) {
  let start = makeDate(item.start, item.allDay ? UNDEFINED : s);
  let end = makeDate(item.end, item.allDay ? UNDEFINED : s);
  let duration = end - start;
  if (overwrite) {
    item.start = start;
    item.end = end;
  }
  start = getDateOnly(start);
  end = s.exclusiveEndDates ? end : getDateOnly(addDays(end, 1));
  // If event has no duration, it should still be added to the start day
  while (start < end || !duration) {
    addToList(obj, start, item);
    start = addDays(start, 1);
    duration = 1;
  }
}

/** @hidden */
function addToList(obj: { [key: string]: any }, d: Date, data: any) {
  const key = getDateStr(d);
  if (!obj[key]) {
    obj[key] = [];
    // Stored the date object on the array for performance reasons, so we don't have to parse it again later
    // TODO: do this with proper types
    obj[key].date = getDateOnly(d, true);
  }
  obj[key].push(data);
}

/** @hidden */
function getExceptionDateMap(
  dtStart: Date | null,
  start: Date,
  end: Date,
  s: IDatetimeProps,
  exception?: string | Date,
  exceptionRule?: MbscRecurrenceRule | string,
): { [key: string]: boolean } {
  const map: { [key: string]: boolean } = {};

  if (exception) {
    const exceptionDates = getExceptionList(exception);
    for (const e of exceptionDates) {
      map[getDateStr(makeDate(e))] = true;
    }
  }

  if (exceptionRule) {
    // Get exception date list from the rule
    const exceptionDateList = getOccurrences(exceptionRule, dtStart, dtStart, start, end, s) as IOccurrence[];
    for (const ex of exceptionDateList) {
      map[getDateStr(ex.d)] = true;
    }
  }

  return map;
}

/** @hidden */
function getDateFromItem(item: any) {
  // If the item is a string, Date, or moment object, it's directly the date (e.g. in case of invalid setting),
  // otherwise check the d or start attributes
  return isString(item) || item.getTime || item.toDate ? item : item.start || item.date;
}

/** @hidden */
export function getTzOpt(s: IDatetimeProps, event: MbscCalendarEvent, dataAsDisplay?: boolean) {
  const origStart = event.original ? event.original.start : event.start;
  const allDay = event.allDay || !origStart;
  const tz = s.timezonePlugin;
  const dataTimezone = event.timezone || s.dataTimezone || s.displayTimezone;
  return tz && !allDay
    ? {
        dataTimezone,
        displayTimezone: dataAsDisplay ? dataTimezone : s.displayTimezone,
        timezonePlugin: tz,
      }
    : UNDEFINED;
}

/** @hidden */
export function parseRule(ruleStr: string): MbscRecurrenceRule {
  const rule: any = {};
  const pairs = ruleStr.split(';');
  for (const pair of pairs) {
    const values = pair.split('=');
    const key = values[0].trim().toLowerCase();
    const value = values[1].trim();
    rule[RULE_KEY_MAP[key] || key] = value;
  }
  return rule as MbscRecurrenceRule;
}

/** @hidden */
export function getRule(rule: string | MbscRecurrenceRule | undefined): MbscRecurrenceRule {
  return isString(rule) ? parseRule(rule) : { ...rule };
}

/**
 * Updates a recurring rule, based on a new start date and old start date.
 * @param recurringRule
 * @param newStart
 * @param oldStart
 */
export function updateRecurringRule(
  recurringRule: MbscRecurrenceRule | string,
  newStart: Date | string | object,
  oldStart: Date | string | object,
): MbscRecurrenceRule {
  const updatedRule = getRule(recurringRule);
  const newStartDate = makeDate(newStart);
  const oldStartDate = makeDate(oldStart);
  const dayDelta = getDayDiff(oldStartDate, newStartDate);
  const repeat = (updatedRule.repeat || '').toLowerCase();

  const updateArray = (values: any | any[], oldValue: any, newValue: any) => {
    const newValues = values.filter((value: any) => value !== oldValue);
    if (newValues.indexOf(newValue) === -1) {
      newValues.push(newValue);
    }
    return newValues;
  };

  const updateNumber = (values: any, oldValue: number, newValue: number) => {
    const oldValues = isArray(values) ? values : ((values || '') + '').split(',').map((nr) => +nr);
    const newValues = updateArray(oldValues, oldValue, newValue);
    return newValues.length > 1 ? newValues : newValues[0];
  };

  const updateWeekDays = () => {
    if (updatedRule.weekDays) {
      const oldWeekDays = updatedRule.weekDays.split(',');
      const oldWeekDay = WEEK_DAYNAMES[oldStartDate.getDay()];
      const newWeekDay = WEEK_DAYNAMES[newStartDate.getDay()];
      const newWeekDays = updateArray(oldWeekDays, oldWeekDay, newWeekDay);
      updatedRule.weekDays = newWeekDays.join();
    }
  };

  if (repeat === 'weekly') {
    updateWeekDays();
  } else if (repeat === 'monthly') {
    if (updatedRule.pos === UNDEFINED) {
      updatedRule.day = updateNumber(updatedRule.day, oldStartDate.getDate(), newStartDate.getDate());
    } else {
      updateWeekDays();
    }
  } else if (repeat === 'yearly') {
    if (updatedRule.pos === UNDEFINED) {
      updatedRule.month = updateNumber(updatedRule.month, oldStartDate.getMonth() + 1, newStartDate.getMonth() + 1);
      updatedRule.day = updateNumber(updatedRule.day, oldStartDate.getDate(), newStartDate.getDate());
    } else {
      updateWeekDays();
    }
  }

  if (updatedRule.from) {
    updatedRule.from = addDays(makeDate(updatedRule.from), dayDelta);
  }

  if (updatedRule.until) {
    updatedRule.until = addDays(makeDate(updatedRule.until), dayDelta);
  }

  return updatedRule;
}

/**
 * Updates a recurring event, returns the updated and the new event.
 * @param originalRecurringEvent The original event to update.
 * @param oldEventOccurrence The original event occurrence in case of d&d (what is dragged).
 * @param newEvent The created even in case of d&d (where is dragged).
 * @param updatedEvent The updated event from popup.
 * @param updateMode The update type.
 */
export function updateRecurringEvent(
  originalRecurringEvent: MbscCalendarEvent,
  oldEventOccurrence: MbscCalendarEvent | null,
  newEvent: MbscCalendarEvent | null,
  updatedEvent: MbscCalendarEvent | null,
  updateMode: 'current' | 'following' | 'all',
  timezone?: string,
  timezonePlugin?: any,
): { updatedEvent: MbscCalendarEvent; newEvent: MbscCalendarEvent | null } {
  let retUpdatedEvent: MbscCalendarEvent = { ...originalRecurringEvent };
  let retNewEvent: MbscCalendarEvent | null = null;
  let newStart = newEvent && newEvent.start;
  let newEnd = newEvent && newEvent.end;
  let newRule: MbscRecurrenceRule | undefined;

  const oldStart = oldEventOccurrence && oldEventOccurrence.start;
  const originalRule = getRule(originalRecurringEvent.recurring);

  switch (updateMode) {
    case 'following':
      if (updatedEvent) {
        // edit from popup
        if (updatedEvent.recurring) {
          newRule = getRule(updatedEvent.recurring);
        }
        retNewEvent = { ...updatedEvent };
        delete retNewEvent.id;
      } else if (newStart && oldStart) {
        // drag & drop
        newRule = updateRecurringRule(originalRule, newStart, oldStart);
        retNewEvent = { ...newEvent };
      }

      // set the hours to 00:00
      originalRule.until = getDateStr(makeDate(oldStart));

      if (originalRule.count) {
        const oldNr = (oldEventOccurrence && oldEventOccurrence.nr) || 0;
        if (newRule) {
          newRule.count = originalRule.count - oldNr;
        }
        originalRule.count = oldNr;
      }

      if (newStart && newRule) {
        newRule.from = newStart;
      }

      if (retNewEvent && newRule) {
        retNewEvent.recurring = newRule;
      }

      retUpdatedEvent.recurring = originalRule;

      break;
    case 'all':
      if (updatedEvent) {
        // edit from popup
        newStart = updatedEvent.start;
        newEnd = updatedEvent.end;
        retUpdatedEvent = { ...updatedEvent };
      } else if (newEvent && newStart && newEnd && oldStart) {
        // drag & drop
        retUpdatedEvent.allDay = newEvent.allDay;
        retUpdatedEvent.recurring = updateRecurringRule(originalRule, newStart, oldStart);
      }

      if (newStart && newEnd) {
        const tzOpt = timezone && timezonePlugin ? { displayTimezone: timezone, timezonePlugin } : UNDEFINED;
        const tzOpt1 = retUpdatedEvent.allDay ? UNDEFINED : tzOpt;
        const tzOpt2 = originalRecurringEvent.allDay ? UNDEFINED : tzOpt;
        const start = makeDate(newStart, tzOpt1);
        const end = makeDate(newEnd, tzOpt1);
        const origStart = originalRecurringEvent.start;
        const origEnd = originalRecurringEvent.end;
        const allDayChange = originalRecurringEvent.allDay && !retUpdatedEvent.allDay;
        const origStartDate = origStart && makeDate(origStart, tzOpt2);
        const oldStartDate = oldStart && makeDate(oldStart, tzOpt2);
        const duration = end - start;
        const delta = oldStartDate ? start - oldStartDate : 0;
        const updatedStart = origStartDate && oldStartDate ? createDate(tzOpt1, +origStartDate + delta) : start;
        const updatedEnd = createDate(tzOpt1, +updatedStart + duration);

        if (isTime(origStart) || (!origStart && allDayChange)) {
          // Set the time only
          retUpdatedEvent.start = formatDate('HH:mm', start);
        } else if (origStart) {
          retUpdatedEvent.start = tzOpt1 ? updatedStart.toISOString() : updatedStart;
        }

        if (isTime(origEnd) || (!origEnd && allDayChange)) {
          // Set the time only
          retUpdatedEvent.end = formatDate('HH:mm', end);
        } else if (origEnd) {
          retUpdatedEvent.end = tzOpt1 ? updatedEnd.toISOString() : updatedEnd;
        }
      }

      break;
    default: {
      const originalException = originalRecurringEvent.recurringException;
      const exception = isArray(originalException) ? [...originalException] : originalException ? [originalException] : [];

      if (oldStart) {
        exception.push(oldStart);
      }

      retUpdatedEvent.recurringException = exception;

      // from popup or drag & drop
      retNewEvent = updatedEvent || newEvent;
      break;
    }
  }

  return { updatedEvent: retUpdatedEvent, newEvent: retNewEvent };
}

/**
 * @hidden
 * Returns the first date on which occurs something from the list of rules/dates
 * For example it returns the next invalid date from the list of invalid and a given start date
 */
export function getNextOccurrence(list: any[], from: Date, s: IDatetimeProps, displayFormat?: string): Date | null {
  // this will hold the next invalid date or null if none was found
  let closest: Date | null = null;
  // loop through all the invalid entries to find the closest date to the starting point
  for (const item of list) {
    if (item.recurring) {
      // Recurring rule
      const dtStart = makeDate(item.start || item.date);
      const firstOccurrence = getOccurrences(
        item.recurring,
        dtStart,
        dtStart,
        from,
        UNDEFINED,
        s,
        item.reccurringException,
        item.recurringExceptionRule,
        'first',
      ) as Date;
      if (!closest || firstOccurrence < closest) {
        closest = firstOccurrence;
      }
    } else if (item.start && item.end) {
      // Range with start/end
      const start = makeDate(item.start, s, displayFormat);
      const end = makeDate(item.end, s, displayFormat);
      if (end > from) {
        if (start <= from) {
          closest = from;
        } else {
          closest = closest && closest < start ? closest : start;
        }
      }
    } else {
      // Exact date
      const exactDate = makeDate(getDateFromItem(item), s, displayFormat);
      if (exactDate > from && (!closest || exactDate < closest)) {
        closest = exactDate;
      }
    }
  }
  return closest;
}

/**
 * @hidden
 * Returns the latest possible date from a list without braking a consecutive day sequence.
 */
export function getLatestOccurrence(list: any[], from: Date, s: IDatetimeProps, displayFormat?: string): Date {
  let latest = from;
  // Sort entries by start date
  list.sort((a, b) => {
    const d1 = makeDate(getDateFromItem(a), s, displayFormat);
    const d2 = makeDate(getDateFromItem(b), s, displayFormat);
    return d1 - d2;
  });
  // Loop through the list to find the latest entry
  for (const item of list) {
    if (item.recurring) {
      // Recurring rule
      const dtStart = makeDate(item.start || item.date);
      const latestOccurrence = getOccurrences(
        item.recurring,
        dtStart,
        dtStart,
        from,
        UNDEFINED,
        s,
        item.reccurringException,
        item.recurringExceptionRule,
        'last',
      ) as Date;
      if (latestOccurrence > latest) {
        latest = latestOccurrence;
      }
    } else if (item.start && item.end) {
      // Range with start/end
      const start = makeDate(item.start, s, displayFormat);
      const end = makeDate(item.end, s, displayFormat);
      if (end > latest && getDayDiff(latest, start) <= 1) {
        latest = end;
      }
    } else {
      // Exact date
      const exactDate = makeDate(getDateFromItem(item), s, displayFormat);
      if (exactDate > latest && getDayDiff(latest, exactDate) <= 1) {
        latest = exactDate;
      }
    }
  }
  return latest;
}

/** @hidden */
export function getExceptionList(exception?: string | object | Date): Array<string | object | Date> {
  if (exception) {
    if (isArray(exception)) {
      return exception;
    }
    if (isString(exception)) {
      return exception.split(',');
    }
    return [exception];
  }
  return [];
}

/** @hidden */
export function getOccurrences(
  rule: MbscRecurrenceRule | string,
  dtStart: Date | null,
  origStart: Date | null,
  start: Date,
  end: Date | undefined,
  s: IDatetimeProps,
  exception?: string | Date,
  exceptionRule?: MbscRecurrenceRule | string,
  returnOccurrence?: 'first' | 'last' | 'all',
): IOccurrence[] | Date | null {
  if (isString(rule)) {
    rule = parseRule(rule);
  }

  const getYear = s.getYear!;
  const getMonth = s.getMonth!;
  const getDay = s.getDay!;
  const getDate = s.getDate!;
  const getMaxDays = s.getMaxDayOfMonth!;
  const freq = (rule.repeat || '').toLowerCase();
  const interval = rule.interval || 1;
  const count = rule.count;
  // the staring point of the current rule
  const from = rule.from ? makeDate(rule.from) : dtStart || (interval !== 1 || count !== UNDEFINED ? new Date() : start);
  const fromDate = getDateOnly(from);
  const fromYear = getYear(from);
  const fromMonth = getMonth(from);
  const fromDay = getDay(from);
  const origHours = origStart ? origStart.getHours() : 0;
  const origMinutes = origStart ? origStart.getMinutes() : 0;
  const origSeconds = origStart ? origStart.getSeconds() : 0;
  const until = rule.until ? makeDate(rule.until) : Infinity;
  const occurredBefore = from < start;
  const rangeStart = occurredBefore ? start : getDateOnly(from);
  const firstOnly = returnOccurrence === 'first';
  const lastOnly = returnOccurrence === 'last';
  const rangeEnd = firstOnly || lastOnly || !end ? until : until < end ? until : end;
  const countOrInfinity = count === UNDEFINED ? Infinity : count;
  const weekDays = (rule.weekDays || WEEK_DAYNAMES[from.getDay()]).split(',');
  const weekStart = WEEK_DAYS[(rule.weekStart || 'MO').trim().toUpperCase()];
  const days = isArray(rule.day) ? (rule.day as number[]) : ((rule.day || fromDay) + '').split(',');
  const months = isArray(rule.month) ? (rule.month as number[]) : ((rule.month || fromMonth + 1) + '').split(',');
  const occurrences: IOccurrence[] = [];
  const hasPos = rule.pos !== UNDEFINED;
  const pos = hasPos ? +rule.pos! : 1;
  const weekDayValues: number[] = [];

  let exceptionDateMap: { [key: string]: boolean } = end ? getExceptionDateMap(dtStart, start, end, s, exception, exceptionRule) : {};
  let first: Date;
  let iterator: Date;
  let repeat = true;
  let i = 0;
  let nr = 0;
  let closest: Date | null = null;
  let latest = start;

  for (const weekDay of weekDays) {
    weekDayValues.push(WEEK_DAYS[weekDay.trim().toUpperCase()]);
  }

  const handleOccurrence = () => {
    // If end is not specified, get the exception dates for the current day
    if (!end) {
      exceptionDateMap = getExceptionDateMap(iterator, iterator, addDays(iterator, 1), s, exception, exceptionRule);
    }
    // Check that it's not an exception date and it's after the start of the range
    if (!exceptionDateMap[getDateStr(iterator)] && iterator >= rangeStart) {
      if (firstOnly) {
        // if it is closer to the start than the current one, stop looking further
        closest = !closest || iterator < closest ? iterator : closest;
        repeat = false;
      } else if (lastOnly) {
        const diff = getDayDiff(latest, iterator);
        latest = iterator > latest && diff <= 1 ? iterator : latest;
        repeat = diff <= 1;
      } else {
        occurrences.push({ d: iterator, i: nr });
      }
    }
    nr++;
  };

  const handlePos = (monthFirstDay: Date, monthLastDay: Date) => {
    const matches: number[] = [];

    for (const weekDay of weekDayValues) {
      const startWeekDay = getFirstDayOfWeek(monthFirstDay, { firstDay: weekDay });
      for (const d = startWeekDay; d < monthLastDay; d.setDate(d.getDate() + 7)) {
        if (d.getMonth() === monthFirstDay.getMonth()) {
          matches.push(+d);
        }
      }
    }

    matches.sort();

    const match = matches[pos < 0 ? matches.length + pos : pos - 1];

    iterator = match ? new Date(match) : monthLastDay;
    iterator = getDate(getYear(iterator), getMonth(iterator), getDay(iterator), origHours, origMinutes, origSeconds);

    if (iterator >= from) {
      if (iterator <= rangeEnd && nr < countOrInfinity) {
        if (match) {
          handleOccurrence();
        }
      } else {
        repeat = false;
      }
    }
  };

  switch (freq) {
    case 'daily':
      nr = count && occurredBefore ? floor(getDayDiff(from, start) / interval) : 0;
      while (repeat) {
        iterator = getDate(fromYear, fromMonth, fromDay + nr * interval, origHours, origMinutes, origSeconds);
        if (iterator <= rangeEnd && nr < countOrInfinity) {
          handleOccurrence();
        } else {
          repeat = false;
        }
      }
      break;
    case 'weekly': {
      // const nrByDay: { [key: number]: number } = {};
      const sortedDays: number[] = weekDayValues;
      const fromFirstWeekDay = getFirstDayOfWeek(from, { firstDay: weekStart });
      const fromWeekDay = fromFirstWeekDay.getDay();
      // const startFirstWeekDay = getFirstDayOfWeek(start, { firstDay: weekStart });
      // Sort week day numbers to start with from day
      sortedDays.sort((a: number, b: number) => {
        a = a - fromWeekDay;
        a = a < 0 ? a + 7 : a;
        b = b - fromWeekDay;
        b = b < 0 ? b + 7 : b;
        return a - b;
      });
     
      while (repeat) {
        for (const weekDay of sortedDays) {
          first = addDays(fromFirstWeekDay, weekDay < weekStart ? weekDay - weekStart + 7 : weekDay - weekStart);
          iterator = getDate(getYear(first), getMonth(first), getDay(first) + i * 7 * interval, origHours, origMinutes, origSeconds);
          if (iterator <= rangeEnd && nr < countOrInfinity) {
            if (iterator >= fromDate) {
              handleOccurrence();
            }
          } else {
            repeat = false;
          }
        }
        i++;
      }
      break;
    }
    case 'monthly':
      // TODO: calculate occurrences before start instead of iterating through all
      while (repeat) {
        const maxDays = getMaxDays(fromYear, fromMonth + i * interval);
        if (hasPos) {
          const monthFirstDay = getDate(fromYear, fromMonth + i * interval, 1);
          const monthLastDay = getDate(fromYear, fromMonth + i * interval + 1, 1);
          handlePos(monthFirstDay, monthLastDay);
        } else {
          for (const d of days) {
            const day = +d;
            iterator = getDate(fromYear, fromMonth + i * interval, day < 0 ? maxDays + day + 1 : day, origHours, origMinutes, origSeconds);
            if (iterator <= rangeEnd && nr < countOrInfinity) {
              if (maxDays >= day && iterator >= fromDate) {
                handleOccurrence();
              }
            } else {
              repeat = false;
            }
          }
        }
        i++;
      }
      break;
    case 'yearly':
      // TODO: calculate occurrences before start instead of iterating through all
      while (repeat) {
        for (const m of months) {
          const month = +m;
          const maxDays = getMaxDays(fromYear + i * interval, month - 1);
          if (hasPos) {
            const monthFirstDay = getDate(fromYear + i * interval, month - 1, 1);
            const monthLastDay = getDate(fromYear + i * interval, month, 1);
            handlePos(monthFirstDay, monthLastDay);
          } else {
            for (const d of days) {
              const day = +d;
              iterator = getDate(
                fromYear + i * interval,
                month - 1,
                day < 0 ? maxDays + day + 1 : day,
                origHours,
                origMinutes,
                origSeconds,
              );
              if (iterator <= rangeEnd && nr < countOrInfinity) {
                if (maxDays >= day && iterator >= fromDate) {
                  handleOccurrence();
                }
              } else {
                repeat = false;
              }
            }
          }
        }
        i++;
      }
      break;
  }
  return firstOnly ? closest : lastOnly ? latest : occurrences;
}

/** @hidden */
export function getEventMap(
  list: any[],
  start: Date,
  end: Date,
  s: IDatetimeProps,
  overwrite?: boolean,
): { [key: string]: any } | undefined {
  const obj = {};

  if (!list) {
    return UNDEFINED;
  }

  for (const item of list) {
    const tzOpt = getTzOpt(s, item, true);
    const tzOptDisplay = getTzOpt(s, item);
    const d = getDateFromItem(item);
    const dt = makeDate(d, tzOptDisplay);
    if (item.recurring) {
      // Use a timezone-less start for getting the occurrences, since getOccurrences does not use timezones
      const dtStart = ISO_8601_TIME.test(d) ? null : makeDate(d);
      const origStart = createDate(tzOpt, dt);
      const oEnd = item.end ? makeDate(item.end, tzOpt) : origStart;
      const origEnd = item.end === '00:00' ? addDays(oEnd, 1) : oEnd;
      const duration = +origEnd - +origStart;
      // We need to extend the range with 1-1 days, because
      // start/end is in local timezone, but data is in data timezone.
      // We cannot convert start/end to data timezone, because the time part is not relevant here.
      const from = addDays(start, -1);
      const until = addDays(end, 1);
      const dates = getOccurrences(
        item.recurring,
        dtStart,
        origStart,
        from,
        until,
        s,
        item.recurringException,
        item.recurringExceptionRule,
      ) as IOccurrence[];

      for (const occurrence of dates) {
        const date = occurrence.d;
        // For each occurrence create a clone of the event
        const clone = { ...item };
        // Modify the start/end dates for the occurrence
        if (item.start) {
          clone.start = createDate(
            tzOpt,
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            origStart.getHours(),
            origStart.getMinutes(),
            origStart.getSeconds(),
          );
        } else {
          clone.allDay = true;
          clone.start = createDate(UNDEFINED, date.getFullYear(), date.getMonth(), date.getDate());
        }
        if (item.end) {
          if (item.allDay) {
            // In case of all-day events keep the length in days, end set the original time for the end day
            const endDay = addDays(date, getDayDiff(origStart, origEnd));
            clone.end = new Date(
              endDay.getFullYear(),
              endDay.getMonth(),
              endDay.getDate(),
              origEnd.getHours(),
              origEnd.getMinutes(),
              origEnd.getSeconds(),
            );
          } else {
            // In case of non all-day events keep the event duration
            clone.end = createDate(tzOpt, +clone.start + duration);
          }
        }
        // Save the occurrence number
        clone.nr = occurrence.i;
        // Set uid
        clone.occurrenceId = clone.id + '_' + getDateStr(clone.start);
        // Save reference to the original event
        clone.original = item;
        if (clone.start && clone.end) {
          addMultiDayEvent(obj, clone, s, overwrite);
        } else {
          addToList(obj, date, clone);
        }
      }
    } else if (item.start && item.end) {
      addMultiDayEvent(obj, item, s, overwrite);
    } else if (dt) {
      // Exact date
      addToList(obj, dt, item);
    }
  }
  return obj;
}
